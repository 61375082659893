import { Visitor, Locations } from './types'
import Profile from '../assets/media/holger_schulze/profile.jpg'
import Handbook from '../assets/media/holger_schulze/handbook.jpg'

const Schulze: Visitor = {
  name: 'Holger Schulze',
  slug: 'holger-schulze',
  logos: ['cmci', 'cdem' ],
  urls: [
    'http://www.soundstudieslab.org'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Holger Schulze',
    position: '50% 0%',
    description: 'Photo portrait of Holger Schulze from the front looking straight into the camera with a black shirt and blue blazer.'
  },
  bio: 'Holger Schulze is full professor in musicology at the University of Copenhagen and principal investigator at the Sound Studies Lab. His research moves between a cultural history of the senses, sound in popular culture and the anthropology of media. He was visiting professor at the Musashino Art University Tokyo, at the University of New South Wales Sydney, Leuphana Universität Lüneburg, and the Humboldt-Universität zu Berlin. He served as associate investigator at the cluster of excellence Image Knowledge Gestaltung at Humboldt-Universität and as vice chair for the European Sound Studies Association. He is founding editor of the book series Sound Studies, produced radio features for Deutschlandfunk Kultur, and collaborated with the Haus der Kulturen der Welt Berlin. He writes for Merkur, Seismograf, Neue Zeitschrift für Musik, Positionen. Publications include: Sonic Fiction (2021), The Bloomsbury Handbook of Sound Art (2020, co-ed.), The Sonic Persona (2018), Sound as Popular Culture (2016, co-ed.).',
  events: [
    {
      kind: 'talk',
      title: 'Sound Art in the 21st Century',
      subtitle: 'Critical Sonification, Sonic Thinkers & Craftsmanship in Sound',
      location: Locations.Hale270,
      start:  new Date("2023-04-19T17:00-06:00"),
      end:  new Date("2023-04-19T18:30-06:00"),
      blurb: 'What was sound art in the 20th century and what might it become in the current century? As a first step, this talk will review the dominant tendencies in writing the history of sound art as it emerged and expanded in the course of the 20th century. Originating at the intersection of various art forms, modes of knowledge and practices of media use, it still carries a surprisingly heavy burden of Eurocentrism and struggles with the art market. Meanwhile, a wide range of sound art has blossomed that defies traditional notions of the art form, such as critical sonification, sonic thinking or a craftsmanship in sound - to name but a few. In a second step, this talk will look at these three species of sound art and how they are leading scholars to rewrite the history of sound art. In a final step, these recent transformations will be summarized in order to anticipate further developments within sound art - including species of sound art that have not been discussed in depth in the second part of this talk. In the end, this talk hopes to offer a new understanding of contemporary and future sound art.',
      blurbJSX: (<div className='flex flex-col gap-2'><div>What was sound art in the 20th century and what might it become in the current century? As a first step, this talk will review the dominant tendencies in writing the history of sound art as it emerged and expanded in the course of the 20th century. Originating at the intersection of various art forms, modes of knowledge and practices of media use, it still carries a surprisingly heavy burden of Eurocentrism and struggles with the art market. Meanwhile, a wide range of sound art has blossomed that defies traditional notions of the art form, such as critical sonification, sonic thinking or a craftsmanship in sound - to name but a few. In a second step, this talk will look at these three species of sound art and how they are leading scholars to rewrite the history of sound art. In a final step, these recent transformations will be summarized in order to anticipate further developments within sound art - including species of sound art that have not been discussed in depth in the second part of this talk. In the end, this talk hopes to offer a new understanding of contemporary and future sound art.</div><div>Literature:<div className="text-sm pl-4">Marcel Cobussen, Vincent Meelberg & Barry Truax (eds.), The Routledge Companion to Sounding Art, New York: Routledge, 2017.<br />Sanne Groth & Holger Schulze (eds.), The Bloomsbury Handbook of Sound Art, New York: Blomsbury Academic, 2020<br/>Helga de la Motte-Haber (ed.), Klangkunst: Handbuch der Musik im 20. Jahrhundert, Bd 12. Laaber: Laaber-Verlag, 1999</div></div></div>),
      media: [
        {
          type: 'image',
          src: Handbook,
          alt: 'Book cover of The Bloomsbury Handbook of Sound Art',
          position: '50% 70%',
          description:
            'Cover of "The Bloomsbury Handbook of Sound Art", recently co-edited by Holger Schulze and Sanne Groth',
        },

      ]
    },
  ]
}

export default Schulze
