import { Visitor, Locations } from './types'
import Profile from '../assets/media/erika_balsom/profile.jpg'
import NoMaster from '../assets/media/erika_balsom/No-Master-Territories_HKW_Foto-_Studio-Bowie_010_web.jpg'

const Balsom: Visitor = {
  name: 'Erika Balsom',
  slug: 'erika-balsom',
  logos: ['cmci', 'cdem'],
  urls: [
    'https://erikabalsom.com'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Erika Balsom',
    description: 'Photo portrait of Erika Balsom in black coat with shoulder-length brown hair, smiling and looking to the left of view.'
  },
  bio: 'Erika Balsom is a reader in film studies at King’s College London. She is the author of four books, including After Uniqueness: A History of Film and Video Art in Circulation (Columbia University Press, 2017) and TEN SKIES (Fireflies Press, 2021, shortlisted for the Kraszna Krausz prize). Her criticism appears regularly in venues such as Artforum, Cinema Scope, and 4Columns. With Hila Peleg, she is the co-curator of the exhibition “No Master Territories: Feminist Worldmaking and the Moving Image” (HKW Berlin, 2022) and co-editor of the books Feminist Worldmaking and the Moving Image (2022) and Documentary Across Disciplines (2016), both published by MIT Press. In 2018, she was awarded a Philip Leverhulme Prize and the Katherine Singer Kovacs essay award from the Society for Cinema and Media Studies.',
  events: [
    {
      kind: 'talk',
      title: 'No Master Territories',
      subtitle: 'Feminist Worldmaking and the Moving Image',
      location: Locations.Hale270,
      start: new Date("2023-04-10T17:00-06:00"),
      end: new Date("2023-04-10T18:30-06:00"),
      blurb: 'This talk will explore the conceptualization and realization of the project “No Master Territories: Feminist Worldmaking and the Moving Image,” an exhibition and cinema programme that took place at the Haus der Kulturen der Welt in Berlin in summer 2022, co-curated by Erika Balsom and Hila Peleg. At a time when feminism is enjoying a mainstream resurgence but must be reclaimed from a neoliberal emphasis on individual success, and when the hybridization of documentary and artists’ film occupies a vital place in the landscape of contemporary practices, No Master Territories made a strategic return to the past. Concentrating primarily on the period of the 1970s to 1990s, a time when women’s liberation movements took hold internationally, it staged an expansive encounter with nonfiction film and video by over 80 artists and collectives across the spaces of the gallery, the cinema, and a library. This talk will detail the curatorial decisions that shaped the project, with special attention to how it sought to adopt feminist methodologies for the presentation of feminist film history.',
      media: [{
        type: 'image',
        src: NoMaster,
        alt: 'No Master Territories exhibition at Haus der Kulturen der Welt',
        description: 'Photo of dark exhibition room with tall ceilings and many screen based works on display. In the foreground are two chairs facing a screen showing a man with dark hair speaking into a walkie-talkie like device.'
      }],
    }
    // NO RECORDING, ERIKA DOES NOT WANT IT ONLINE
  ]
}

export default Balsom
