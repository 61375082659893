import { Visitor, Locations } from './types'

import Profile from '../assets/media/lauren_lee_mccarthy/profile.jpg'

import ChangingRoom from '../assets/media/lauren_lee_mccarthy/Lauren-Lee-McCarthy_Image-06_The-Changing-Room.jpg'
import Someone from '../assets/media/lauren_lee_mccarthy/Lauren-Lee-McCarthy_Image-04_SOMEONE.jpg'
import WakingAgents from '../assets/media/lauren_lee_mccarthy/Lauren-Lee-McCarthy_Image-05_Waking-Agents.jpg'

const Lauren: Visitor = {
  name: 'Lauren Lee McCarthy',
  logos: ['cmci', 'cdem', 'roser', 'atlas', 'b2'],
  slug: 'lauren-lee-mccarthy',
  urls: ['https://lauren-mccarthy.com', 'https://p5js.org/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Image credit: Gabriel Noguez.',
    description:
      'A Chinese-American woman with short dark hair standing in front of device sculptures.',
  },
  bio: 'Lauren Lee McCarthy is an artist examining social relationships in the midst of surveillance, automation, and algorithmic living. She is the creator of p5.js, an open source creative coding platform that prioritizes inclusion and access. She has received grants and residencies from Creative Capital, United States Artists, LACMA, Sundance, Eyebeam, Pioneer Works, Autodesk, and Ars Electronica. Lauren\'s work has been exhibited internationally, including the Barbican Centre, Ars Electronica, REDCAT, Fotomuseum Winterthur, Haus der elektronischen Künste, SIGGRAPH, Onassis Cultural Center, IDFA, Science Gallery Dublin, and the Seoul Museum of Art. Lauren is a Professor at UCLA Design Media Arts.',
  events: [
    {
      kind: 'talk',
      title: 'I Heard Talking Is Dangerous',
      location: Locations.BruceCurtis,
      start: new Date("2023-09-25T17:00-06:00"),
      end: new Date("2023-09-25T18:30-06:00"),
      blurb: 'I am captivated by the ways we are taught to interact with algorithms, and how this shapes the way we interact with each other. What are these simultaneous technological and social systems we’re building around ourselves? What are the rules, what happens when we introduce glitches? I create performances inviting viewers to engage. To remote control my dates. To be followed. To welcome me in as their human smart home. To attend a party hosted by artificial intelligence. I am embodying machines, trying to understand that distance between the machines and ourselves. Who builds these artificial systems, what values do they embody? Who is prioritized and who is targeted as race, gender, disability, and class are programmatically encoded? In the midst of always on networked interfaces, what does it mean to be truly present?',
      media: [
        {
          type: 'image',
          src: ChangingRoom,
          description: 'View of woman from behind with dark hair, sitting in a chair in a purpleish room looking at a glowing tablet on the wall',
          alt:
            'The Changing Room. A custom software installation, a smart environment that controls feelings, the piece simultaneously invades and cares for the emotions of passersby. Athens, Greece and Ogden, Utah 2021–22. Photo Credit: Cam McLeod'
        },
        {
          type: 'image',
          src: Someone,
          description: 'View of a desk and four empty chairs in a dark room lit from above.  Each chair has a laptop and a lamp on the desk.',
          alt:
            'Someone. For a two month period, four homes around the country were installed with a human Alexa system, while visitors controlled the homes from a gallery in NYC. New York, NY 2020 Photo Credit: Japan Media Arts Festival'
        },
        {
          type: 'image',
          src: WakingAgents,
          description: 'Five people in dark clothing laying on white matresses on top of a wooden platform in an otherwise empty gallery',
          alt:
            'Waking Agents. Visitors are invited to lay down and nap with a smart pillow, attended to by its embedded intelligence. Behind the scenes, each pillow is driven by human performer, listening to a live feed and controlling the pillow for the napping participant. Eindhoven, NL and Montepellier, France 2020 Photo Credit: Sébastien Bozon'
        },
      ],
    },

    {
      kind: 'workshop',
      title: 'p5.js workshop',
      location: Locations.Atlas100,
      start: new Date("2023-09-26T09:30-06:00"),
      end: new Date("2023-09-26T10:45-06:00"),
      blurb: 'p5.js is a creative coding platform that prioritizes access and diversity in learning to code. In this workshop, you will get a hands-on introduction by the creator Lauren Lee McCarthy. We will cover the basics of making interactive sketches with code, and talk about the process of making p5.js and working with the p5.js community. Please bring your laptops.',
      media: []
    }
  ],
}

export default Lauren
