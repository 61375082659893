import { Visitor, Locations } from './types'
import Profile from '../assets/media/garnet_hertz/profile.jpg'
import Media01 from '../assets/media/garnet_hertz/media01.jpg'
import Media02 from '../assets/media/garnet_hertz/media02.jpg'
import Media03 from '../assets/media/garnet_hertz/media03.jpg'
import Media04 from '../assets/media/garnet_hertz/media04.jpg'
import Media05 from '../assets/media/garnet_hertz/media05.jpg'

const Hertz: Visitor = {
  name: 'Garnet Hertz',
  slug: 'garnet-hertz',
  logos: ['mal', 'cmci', 'iawp', 'cdem' ],
  urls: [
    'https://conceptlab.com'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Garnet Hertz',
    description: 'Portrait photo of Garnet Hertz with black t-shirt and half-smile, hands folded over the knees, surrounded by old televisions',
    position: 'center 10%'
  },
  bio: 'Dr. Garnet Hertz is Canada Research Chair in Design and Media Arts, and is Associate Professor of Design at Emily Carr University. His art and research investigates DIY culture, electronic art and critical design practices. He has exhibited in eighteen countries including SIGGRAPH, Ars Electronica, and DEAF and was Oscar Signorini Award in robotic art and a Fulbright award. He has worked at Art Center College of Design and University of California Irvine. His research is widely cited in academic publications, and popular press on his work has disseminated through 25 countries including The New York Times, Wired, The Washington Post, NPR, USA Today, NBC, CBS, TV Tokyo and CNN Headline News.',
  events: [
    {
      kind: 'talk',
      title: 'Bending the Future',
      subtitle: 'The Alternate Present of Media Archaeology as an Art Methodology',
      location: Locations.Eaton150,
      start:  new Date("2022-03-09T17:00-07:00"),
      end:  new Date("2022-03-09T18:30-07:00"),
      blurb: 'Media archaeology has been known for its innovative work in excavating repressed, forgotten, or past media technologies in order to understand the contemporary technological audiovisual culture in alternative ways. Failed technologies of the past can either serve as warning signs of what to stay away from or give us inspiration on what to do – and both can help us bend the future of technology into a more interesting, fair, and equitable form. This talk will explore how topics like gender have been highlighted by artists working with technology, and how historical technologies can serve as inspiration for re-imagining technology through different lenses of feminism.',
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/710162858'
      },
      media: [
        {
          type: 'image',
          src: Media01,
          description: 'Photo of blue dress in shop window with direct lights and surrounded by five LED screens with numbers',
          alt: 'Nancy Paterson, Stock Market Skirt (1998), front view'
        },
        {
          type: 'image',
          src: Media02,
          alt: 'Nancy Paterson, Stock Market Skirt (1998), close up',
          description: 'Close up photo a blue dress being lifted up to reveal mechatronics underneath.'
        },
        {
          type: 'image',
          src: Media03,
          alt: 'Nancy Paterson, Stock Market Skirt (1998), in the studio',
          description: 'Photo of Nancy underneath the blue dress in her studio with old CRT monitor and keyboard in the background.'
        }
      ]
    },
    {
      kind: 'workshop',
      title: 'Reimaging the Now',
      subtitle: 'Rethinking Technology Through Feminism',
      blurb: 'Media artist-scholar Garnet Hertz will present a workshop at CU Boulder in the CASE Building, Room W311, on March 8th, 2022, 9:00 am - 12:00 pm. The workshop is part of the 2021-2022 mini lecture series on Media Archaeology & Feminism, organized and sponsored by the Department of Critical Media Practices, the Media Archaeology Lab, and the Program in Intermedia Art, Writing and Performance.',
      location: Locations.CaseW311,
      start:  new Date("2022-03-08T09:00-07:00"),
      end:  new Date("2022-03-08T08:12-07:00"),
      media: [
        {
          type: 'image',
          src: Media04,
          alt: 'Alice Bygrave, Patented Convertible Pulley Cycling Skirt (1895)',
          description: 'Black and white lined graphic of two figures in old victorian clothing and one dress.'
        },
        {
          type: 'image',
          src: Media05,
          alt: 'Kaiwen Yang, Bending History With Design (2021)',
          description: 'Black and white info graphic with two curved lines that attempts to describe possible futures.'
        }
      ]
    }
  ]
}

export default Hertz
