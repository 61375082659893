import { Visitor, Locations } from './types'
import Profile from '../assets/media/joan_brandi/profile.jpg'

const JoanBrandi: Visitor = {
  name: 'Joan Donovan & Brandi Collins-Dexter',
  logos: ['medlab', 'cmci', 'cdem'],
  slug: 'donovan-collins-dexter',
  urls: ['https://celadonbooks.com/authors/brandi-collins-dexter/', 'https://shorensteincenter.org/dr-joan-donovan-named-shorenstein-center-research-director/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Brandi Collins-Dexter (L) and Joan Donovan (R)',
    description:
      'Photo portrait of the speakers. On the left is Collins-Dexter, a black women with shoulder length hair, wearing white collared shirt and blue jacket. On the right is Donovan, a white woman with short dark hair, a leather jacket black shirt.',
  },
  bio: 'Brandi Collins-Dexter is associate director of research at the The Technology and Social Change Project (TaSC) housed in Harvard Kennedy School’s Shorenstein Center on Media, Politics and Public Policy. Her first book, Black Skinhead: Reflections on Blackness and Our Political Future, explores Black participation in democracy and the US economy, with particular focus on the role technology, media and economics play in improving or deteriorating community health. Brandi is a former Senior Campaign Director at Color Of Change where she oversaw the media, culture, and economic justice departments.\n\nDr. Joan Donovan is the Research Director of the Shorenstein Center on Media, Politics and Public Policy. Dr. Donovan leads the field in examining internet and technology studies, online extremism, media manipulation, and disinformation campaigns. She leads The Technology and Social Change Project (TaSC), which explores how media manipulation is a means to control public conversation, derail democracy, and disrupt society. She is the co-author of Meme Wars: The Untold Story of the Online Battles Upending Democracy in America.',
  bioJSX: (<div className="space-y-4">
    <div>Brandi Collins-Dexter is associate director of research at the The Technology and Social Change Project (TaSC) housed in Harvard Kennedy School’s Shorenstein Center on Media, Politics and Public Policy. Her first book, Black Skinhead: Reflections on Blackness and Our Political Future, explores Black participation in democracy and the US economy, with particular focus on the role technology, media and economics play in improving or deteriorating community health. Brandi is a former Senior Campaign Director at Color Of Change where she oversaw the media, culture, and economic justice departments.</div>
    <div>Dr. Joan Donovan is the Research Director of the Shorenstein Center on Media, Politics and Public Policy. Dr. Donovan leads the field in examining internet and technology studies, online extremism, media manipulation, and disinformation campaigns. She leads The Technology and Social Change Project (TaSC), which explores how media manipulation is a means to control public conversation, derail democracy, and disrupt society. She is the co-author of Meme Wars: The Untold Story of the Online Battles Upending Democracy in America.
    </div>
  </div>
      ),
  events: [
    {
      kind: 'talk',
      title: 'Meme Wars',
      subtitle: 'Disinformation, Media Manipulation, and Political Futures',
      location: Locations.Hale270,
      start:  new Date("2023-04-12T17:00-06:00"),
      end:  new Date("2023-04-12T18:30-06:00"),
      blurb: 'This event features two Harvard-based scholar-activsts who take seriously media that others would prefer to ignore, from the utterances of Kanye West to online havens of White nationalism. Together, they will guide an exploration of how the tactics and ideas circulating in some of culture\'s most dangerous spaces could be shaping the political possibilities of the future.',
      media: [],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/824421025'
      },

    }
  ]
}

export default JoanBrandi
