import { Visitor, Locations } from './types'

import Profile from '../assets/media/jay_needham/profile.jpg'

const Jay: Visitor = {
  name: 'Jay Needham',
  logos: ['cmci', 'cdem', 'mediastudies'],
  slug: 'jay-needham',
  urls: ['https://www.jayneedham.net/', 'https://www.jayneedham.net/narrative-half-life'],
  profile: {
    type: 'image',
    src: Profile,
    position: '70% 100%',
    alt: 'Jay Needham (photo: Todd Birdsong)',
    description:
      'A white man with glasses and short hair sitting behind a stack of audio equipment and holding an antenna, lit from below and above.',
  },
  bio: 'Jay Needham is an artist, writer/ editor, and educator.As a multi-instrumentalist and visual artist, he creates sound art, music, productions for radio, visual art, performances, and installations that activate listening as a vital component of artistic perception.Through his works, he explores themes of militarism, surveillance, family archives, ecology, and autoethnography that are often informed by his life - long experience with hearing loss. His sound art, works for radio and visual art have appeared at museums, festivals and on the airwaves worldwide.His sound installation documenting the sounds of Neotropical rainforests is on permanent display in the BioMuseo, designed by Frank Gehry in The Republic of Panama. Needham is the editor of Resonance: The Journal of Sound and Culture, published by the University of California Press.His writings have been published in Exposure, Soundscape: The Journal of Acoustic Ecology, and Leonardo Music Journal.Professor Needham is a member of the Humanities and Social Science Expert Group with the Scientific Committee on Antarctic Research(SCAR), as well as a member of the Library of Congress’s Radio Preservation Task Force.He is the Director of Graduate Studies for the MFA program in the School of Media Arts at Southern Illinois University Carbondale.',
  bioJSX: (
    <div>
      <div>Jay Needham is an artist, writer/editor, and educator. As a multi-instrumentalist and visual artist, he creates sound art, music, productions for radio, visual art, performances, and installations that activate listening as a vital component of artistic perception. Through his works, he explores themes of militarism, surveillance, family archives, ecology, and autoethnography that are often informed by his life-long experience with hearing loss.
      </div><div>
        His sound art, works for radio and visual art have appeared at museums, festivals and on the airwaves worldwide. His sound installation documenting the sounds of Neotropical rainforests is on permanent display in the BioMuseo, designed by Frank Gehry in The Republic of Panama.
      </div><div>
        Needham is the editor of Resonance: The Journal of Sound and Culture, published by the University of California Press. His writings have been published in Exposure, Soundscape: The Journal of Acoustic Ecology, and Leonardo Music Journal. Professor Needham is a member of the Humanities and Social Science Expert Group with the Scientific Committee on Antarctic Research (SCAR), as well as a member of the Library of Congress’s Radio Preservation Task Force. He is the Director of Graduate Studies for the MFA program in the School of Media Arts at Southern Illinois University Carbondale.
      </div>
    </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'Narrative Half-Life',
      location: Locations.Eaton150,
      start: new Date("2024-10-17T11:00-06:00"),
      end: new Date("2024-10-17T12:15-06:00"),
      blurb: 'Narrative Half-Life is a continuing series of creative-nonfiction media artworks that co-mingle and complicate family histories as told to me by my grandfather, Lt. Colonel William Sapper, who was a Manhattan Project engineer during the Second World War. During the war years, Bill made sound recordings of friends and colleagues, documenting original jazz music, limericks, and labor poems as well as conducting interviews as a part of the secret atomic culture that was the Manhattan Project. This talk features recently conserved sound recordings that seek to reveal how familial memory, state secrets and personal archives have greatly influenced me as an artist over the course of my career.',
      media: [
      ],
    }
  ]
}

export default Jay 
