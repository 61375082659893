import { Visitor, Locations } from './types'
import Profile from '../assets/media/hasan_elahi/profile.jpg'
import ElahiStay from '../assets/media/hasan_elahi/elahi_stay_6000_x_3863px.jpg'
import ElahiAltitude from '../assets/media/hasan_elahi/elahi_altitude_v3_5046_x_6000px.jpg'
import ElahiPrism from '../assets/media/hasan_elahi/elahi_prism_7200px_x_3000px.jpg'
import ElahiStelae from '../assets/media/hasan_elahi/elahi_stelae_zkm2_5760x3840px_rgb.jpg'
import ElahiOrb from '../assets/media/hasan_elahi/elahi_orb_smoca_7360px_x_4912.jpg'
import ElahiByrd from '../assets/media/hasan_elahi/elahi_byrd_7200px_x_4320px.jpg'
import ElahiHere from '../assets/media/hasan_elahi/elahi_here_v2_5294x3600px.jpg'

const Chavez: Visitor = {
  name: 'Hasan Elahi',
  slug: 'hasan-elahi',
  logos: ['cmci', 'cdem'],
  urls: [
    'http://elahi.org'
  ],
  profile: {
    type: 'image',
    src: Profile,
    position: '0% 0%',
    alt: 'Hasan Elahi',
    description: 'Color portrait photo of Hasan in light grey shirt with grey blazer, a shaved head and dark glass with a slight smile looking directly at the camera.'
  },
  bio: 'Hasan Elahi is an artist whose work examines issues of surveillance, citizenship, migration, transport, and the challenges of borders and frontiers. His work has been presented in numerous exhibitions at venues such as SITE Santa Fe, Centre Georges Pompidou, the Sundance Film Festival, the Gwangju Biennale, and the Venice Biennale. His work is frequently in the media and has been covered by the New York Times, Forbes, Wired, and has appeared on Al Jazeera, Fox News and The Colbert Report. Elahi has spoken about his work to a broad range of audiences such as Tate Modern, Einstein Forum, the American Association of Artificial Intelligence, the International Association of Privacy Professionals, TED and the World Economic Forum. His recent awards include a Guggenheim Fellowship, an Alpert/MacDowell Fellowship, grants from Creative Capital, Art Matters Foundation, the Doris Duke Foundation for Islamic Art and he is a recipient of a Hugh M. Hefner First Amendment Award. He is currently professor and director of the School of Art at George Mason University and lives and works outside of Washington, D.C.',
  bioJSX: (
    <div>
      <div>Hasan Elahi is an artist whose work examines issues of surveillance, citizenship, migration, transport, and the challenges of borders and frontiers. His work has been presented in numerous exhibitions at venues such as SITE Santa Fe, Centre Georges Pompidou, the Sundance Film Festival, the Gwangju Biennale, and the Venice Biennale. His work is frequently in the media and has been covered by the New York Times, Forbes, Wired, and has appeared on Al Jazeera, Fox News and The Colbert Report.
      </div>
      <div>
        Elahi has spoken about his work to a broad range of audiences such as Tate Modern, Einstein Forum, the American Association of Artificial Intelligence, the International Association of Privacy Professionals, TED and the World Economic Forum. His recent awards include a Guggenheim Fellowship, an Alpert/MacDowell Fellowship, grants from Creative Capital, Art Matters Foundation, the Doris Duke Foundation for Islamic Art and he is a recipient of a Hugh M. Hefner First Amendment Award. He is currently professor and director of the School of Art at George Mason University and lives and works outside of Washington, D.C.</div>
    </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'The New Normal',
      blurb: 'After an erroneous tip called into law enforcement authorities in 2002 subjected Elahi to an intensive investigation by the FBI and after undergoing months of interrogations, he was finally cleared of suspicions. After this harrowing experience, Elahi conceived “Tracking Transience” and opened just about every aspect of his life to the public. Predating the NSA’s PRISM surveillance program by half a decade, the project questions the consequences of living under constant surveillance and continuously generates databases of imagery that tracks the artist and his points of transit in real-time. Although initially created for his FBI agent, the public can also monitor the artist’s communication records, banking transactions, and transportation logs along with various intelligence and government agencies who have been confirmed visiting his website.',
      blurbJSX: (
        <div>
          <div>
            After an erroneous tip called into law enforcement authorities in 2002 subjected Elahi to an intensive investigation by the FBI and after undergoing months of interrogations, he was finally cleared of suspicions. After this harrowing experience, Elahi conceived “Tracking Transience” and opened just about every aspect of his life to the public. Predating the NSA’s PRISM surveillance program by half a decade, the project questions the consequences of living under constant surveillance and continuously generates databases of imagery that tracks the artist and his points of transit in real-time.
          </div><div>
            Although initially created for his FBI agent, the public can also monitor the artist’s communication records, banking transactions, and transportation logs along with various intelligence and government agencies who have been confirmed visiting his website.
          </div>
        </div>
      ),
      location: Locations.Hale270,
      start:  new Date("2021-11-01T17:00-06:00"),
      end:  new Date("2021-11-01T18:30-06:00"),
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/684872465'
      },
      media: [
        {
          type: 'image',
          src: ElahiStay,
          alt: 'Stay v1.0 C-print 30 inches x 40 inches / 75 cm x 100 cm 2011',
          description: 'One picture composed of  30 pictures of Hasan\'s various hotel rooms.'
        },
        {
          type: 'image',
          src: ElahiPrism,
          alt: ' Prism pigment on vinyl 10 feet x 24 feet 2015',
          description: 'Photo collage with black and white satellite photo overlayed with 7 vertical bars, each of different color, each composed of smaller images.'
        },
        {
          type: 'image',
          src: ElahiStelae,
          alt: 'Stelae 10.4 feet x 32.5 feet x 20 inches Translucent pigment prints on lightboxes as installed at Zentrum für Kunst und Medientechnologie Karlsruhe, Germany 2015',
          description: 'Picture of video installation with 5 V-shaped lcd screens leaning agains the wals, each of different color and composed of smaller images.'
        },
        {
          type: 'image',
          src: ElahiOrb,
          alt: 'Orb v2.1 72-channel video installation 8 feet x 12 feet x 24 feet as installed at Scottsdale Museum of Contemporary Art, Scottsdale, Arizona 2014',
          description: 'Picture of video installation against a black backdrop with small lcd screens arranged from a central based and hanging from the ceiling like chandeliers.'
        },
        {
          type: 'image',
          src: ElahiByrd,
          alt: 'On this spot stood the historic Byrd Tavern, a famous... pigment print 10 feet x 16 feet 2017',
          description: 'Black and white graphic with stripes and a blob of noise'
        },
        {
          type: 'image',
          src: ElahiHere,
          alt: 'Here v2 neon 9.8 feet x 9.8 feet as installed at Pinacoteca Amedeo Modigliani, Follonica, Italy 2015',
          description: 'Picture of large red neon arrow pointing down to the right towards a door.'
        },
        {
          type: 'image',
          src: ElahiAltitude,
          alt: 'Altitude v3.0 C-print 40 inches x 30 inches / 100 cm x 75 cm 2007',
          description: 'Series of photos of Hasan\'s airplane meals.'
        }


      ]
    }
  ]
}

export default Chavez
