import Link from 'next/link'
import { Visitor, Locations } from './types'
import Profile from '../assets/media/coraline_ada_ehmke/profile.jpg'

const Coraline: Visitor = {
  name: 'Coraline Ada Ehmke',
  logos: ['cmci', 'cdem', 'mal', 'mediastudies', 'medlab'],
  slug: 'coraline-ada-ehmke',
  urls: ['https://ethicalsource.dev', 'https://dl.acm.org/doi/abs/10.1145/3531146.3533779', 'https://where.coraline.codes/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Coraline Ada Ehmke',
    description:
      'white woman with dark black hair and a reddish stripe on the left side, wearing glasses and liptstick and necklace with a circle-over-cross pendant.'
  },
  bio: "Coraline Ada Ehmke is an internationally recognized tech ethicist, activist, and software engineer. For more than a decade, she's worked on practical approaches to promoting the values of diversity, equity, and justice in the technology industry, with a particular focus on open source.She is the creator of Contributor Covenant, the first and most popular code of conduct for digital communities, and the Hippocratic License, an innovative software license designed to promote and protect human rights.Coraline co- founded the Organization for Ethical Source(https://ethicalsource.dev) and serves as its Executive Director.",
  bioJSX: (
    <div>
      <div>
        Coraline Ada Ehmke is an internationally recognized tech ethicist, activist, and software engineer. For more than a decade, she's worked on practical approaches to promoting the values of diversity, equity, and justice in the technology industry, with a particular focus on open source. She is the creator of Contributor Covenant, the first and most popular code of conduct for digital communities, and the Hippocratic License, an innovative software license designed to promote and protect human rights. Coraline co-founded the Organization for Ethical Source (<Link href="https://ethicalsource.dev">https://ethicalsource.dev</Link>) and serves as its Executive Director.
      </div>
    </div >
  ),
  events: [
    {
      kind: 'talk',
      title: 'Four Reasons to Not Care about Ethics in Open Source',
      location: Locations.Chem142,
      start: new Date("2025-01-28T14:00-07:00"),
      end: new Date("2024-01-28T15:15-07:00"),
      blurb: 'Are we, as technologists, responsible for how our work impacts society? In a 2022 paper, researcher David Widder published a study on the justifications given by open source deepfake developers when asked about the moral implications of their work. Four main arguments were made by the developers to deny their ethical responsibility: the Freedom Zero argument, the Open argument, the Tech is Just a Hammer argument, and the Inevitability argument. But do any of these justifications really ring true, or are they comforting fictions that separate us from the real-world impact of our work?',
      blurbJSX: (<div>
        <div>Are we, as technologists, responsible for how our work impacts society?</div>
        <div>In a 2022 paper, researcher David Widder published a study on the justifications given by open source deepfake developers when asked about the moral implications of their work. Four main arguments were made by the developers to deny their ethical responsibility: the Freedom Zero argument, the Open argument, the Tech is Just a Hammer argument, and the Inevitability argument. But do any of these justifications really ring true, or are they comforting fictions that separate us from the real-world impact of our work?</div>
      </div>),
      media: [],
    },
  ],
}

export default Coraline
