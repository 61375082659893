import { Visitor, Locations } from './types'
import Profile from '../assets/media/amy_lauren/profile.png'
import Ice from '../assets/media/amy_lauren/ice.jpg'
import Pool from '../assets/media/amy_lauren/pool.jpg'
import DarkSite from '../assets/media/amy_lauren/darksite.jpg'

const Lauren: Visitor = {
  name: 'Amy Lauren',
  logos: ['cmci', 'cdem'],
  slug: 'amy-lauren',
  urls: ['https://www.amylauren.net'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Amy Lauren',
    description:
      'Photo portrait of Amy with shoulder length brown hair, wearing a winter coat and a smile.',
  },
  bio: 'Amy Lauren is a filmmaker/artist working at the nexus between research, education and artistic practice. Her work pulls focus on elemental stories of water, earth and air.  Turned toward immersive storytelling as a way to combine the perceptual and epistemic aspects of scientific observation, Lauren asks how new modes of narrative experience can facilitate learning about climate science that calls for engagement and participation in the natural world. Lauren is a graduate of the Interdisciplinary Documentary Media Practices MFA program at the University of Colorado Boulder. She collaborates with scientists and researchers to translate their groundbreaking work into captivating visual stories that make the complex world of academic research more approachable to a broader audience.',
  bioJSX: (<div className="space-y-4">
    <div>
      Amy Lauren is a filmmaker/artist working at the nexus between research, education and artistic practice.
    </div><div>
      Her work pulls focus on elemental stories of water, earth and air.  Turned toward immersive storytelling as a way to combine the perceptual and epistemic aspects of scientific observation, Lauren asks how new modes of narrative experience can facilitate learning about climate science that calls for engagement and participation in the natural world.
    </div><div>
      Lauren is a graduate of the Interdisciplinary Documentary Media Practices MFA program at the University of Colorado Boulder. She collaborates with scientists and researchers to translate their groundbreaking work into captivating visual stories that make the complex world of academic research more approachable to a broader audience.
    </div>
  </div>
      ),
  events: [
    {
      kind: 'talk',
      title: 'Art-Science Collaborations at Sea',
      location: Locations.Hale270,
      start:  new Date("2023-02-20T17:00-07:00"),
      end:  new Date("2023-02-20T18:30-07:00"),
      blurb: 'Amy Lauren shares stories of life among scientists during polar night and describes the MOSAiC expedition contextualized through the lens of an artist embedded within the polar research community navigating an art-science collaboration at sea. MOSAiC was the largest polar expedition in history. CU Boulder scientists joined researchers and engineers from over 20 countries to spend a year (2019-2022) trapped in ice drifting across the Arctic Ocean on the German icebreaker, Polarstern. Their goal was a multidisciplinary approach to studying the Arctic as the epicenter of global warming.',
      blurbJSX: (
        <div className="space-y-4">
          <div>
            Amy Lauren shares stories of life among scientists during polar night and describes the MOSAiC expedition contextualized through the lens of an artist embedded within the polar research community navigating an art-science collaboration at sea.
          </div><div>
            MOSAiC was the largest polar expedition in history. CU Boulder scientists joined researchers and engineers from over 20 countries to spend a year (2019-2022) trapped in ice drifting across the Arctic Ocean on the German icebreaker, Polarstern. Their goal was a multidisciplinary approach to studying the Arctic as the epicenter of global warming.
          </div>
        </div>
      ),
      media: [
        {
          type: 'image',
          src: Pool,
          alt: 'Amy Lauren out on the ice',
          description:
            'Photo of Amy in red winter suit laying face down on the ice with her face over a pool of water.',
        },
        {
          type: 'image',
          src: Ice,
          alt: '14 October 2019, Met City',
          description:
            'Photo of icescape at dusk.',
        },
        {
          type: 'image',
          src: DarkSite,
          alt: '18 October 2019, Dark Site',
          description:
            'Photo of landscape with silhouette of figure holding binoculars and behind them in the distance a large well-lit work boat.',
        },
      ],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/814418206'
      },

    },
  ],
}

export default Lauren
