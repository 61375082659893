
import { Visitor, Locations } from './types'
import Profile from '../assets/media/chip_thomas/profile.jpg'
import Sunset from '../assets/media/chip_thomas/sunset.jpg'

const Chip: Visitor = {
  name: 'Chip Thomas',
  logos: ['cmci', 'cdem'],
  slug: 'chip-thomas',
  urls: ['https://jetsonorama.net'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Chip Thomas',
    description:
      'Photo portrait of Chip, a black man with white beard, glases, hat, and puffy jacket looking to the left. Behind him is a large photograph of a face.'
  },
  bio: 'Chip Thomas, aka jetsonorama, is a photographer, public artist and physician who worked in a small clinic on the Navajo Nation from 1987 to 2023. There he coordinated the Painted Desert Project which he describes as a community building effort which manifested as a constellation of murals painted by artists from the Navajo Nation as well as from around the world. Thomas’ own public artwork consists of enlarged black and white photographs pasted onto structures along the roadside primarily on the Navajo Nation but all over the country.His motivation is to build community while reflecting back to Navajo people the love they’ve shared with him over the years.Thomas was a 2018 Kindle Project gift recipient and in 2020 he was one of a handful of artists chosen by the UN to recognize the 75 th anniversary of the UN’s founding. He has no formal artistic training but identifies strongly with the DIY energy of punk and hip hop.',
  bioJSX: (<div className="space-y-4">
    <div>
      Chip Thomas, aka jetsonorama, is a photographer, public artist and physician who worked in a
      small clinic on the Navajo Nation from 1987 to 2023. There he coordinated the Painted Desert
      Project which he describes as a community building effort which manifested as a constellation
      of murals painted by artists from the Navajo Nation as well as from around the world.
    </div><div>
      Thomas’ own public artwork consists of enlarged black and white photographs pasted onto
      structures along the roadside primarily on the Navajo Nation but all over the country. His
      motivation is to build community while reflecting back to Navajo people the love they’ve
      shared with him over the years. Thomas was a 2018 Kindle Project gift recipient and in 2020 he
      was one of a handful of artists chosen by the UN to recognize the 75 th anniversary of the UN’s
      founding. He has no formal artistic training but identifies strongly with the DIY energy of punk
      and hip hop.
    </div>
  </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'Stories Told and Retold',
      location: Locations.Atlas100,
      start: new Date("2024-01-30T14:00-07:00"),
      end: new Date("2024-01-30T15:15-07:00"),
      blurb: 'This talk looks at the factors leading me to develop a photo mural public art project on the Navajo nation and the lessons learned over the 14 years of the project.',
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/910905362'
      },
      media: [
        {
          type: 'image',
          src: Sunset,
          alt: '',
          description:
            'Photo of an isoalted building in a desert landscape with a large mural of black woman on a swing.',
        },
      ],
    },
  ],
}

export default Chip
