import { Visitor, Locations } from './types'
import Profile from '../assets/media/doug_paulson/profile.jpg'
import Park1 from '../assets/media/doug_paulson/DSC_0213.jpg'
import Park2 from '../assets/media/doug_paulson/DSC_0214.jpg'
import Park3 from '../assets/media/doug_paulson/IMG_4321.jpg'
import Park4 from '../assets/media/doug_paulson/IMG_4380.jpg'
// import LetsTalk from '../assets/media/doug_paulson/letstalk_book.jpg'

const Doug: Visitor = {
  name: 'Douglas Paulson',
  slug: 'douglas_paulson',
  logos: ['cmci', 'cdem'],
  urls: ['http://douglaspaulson.com', 'http://socratessculpturepark.org'],
  profile: {
    type: 'image',
    src: Profile,
    position: '35% 100%',
    alt: 'Doug Paulson',
    description:
      'Photo portrait of Doug in glass and short beard, and wearing a long pointy hat made of kelp.',
  },
  bio: 'Douglas Paulson has been developing an expansive, socially engaged and collaborative practice for over 15 years. His work takes many forms, from sprawling social projects in public space, to intimate drawings, books, and sonic experiments. Collaboration is at the heart of his practice. His work has come to life across NYC and abroad, bringing together people from disparate backgrounds into conversation with each other. Doug has explored the state of public art in Seoul; partnered kids with artists and urban planners to design and build Kitty City, a city for kittens. He and Heidi Neilson inflated the Menu For Mars Kitchen to prototype food for the Red Planet. He’s collaborated with refugees from the Middle East and Africa who have relocated to Germany, Norway, and Israel. He’s currently Director of Education at Socrates Sculpture Park, designing moments for neighbors to have encounters with art making, the natural world, mindfulness; and organizing wild performative experiments with young people.',
  bioJSX: (
    <div className="flex flex-col space-y-4">
      <div>
        Douglas Paulson has been developing an expansive, socially engaged and
        collaborative practice for over 15 years. His work takes many forms,
        from sprawling social projects in public space, to intimate drawings,
        books, and sonic experiments.
      </div>
      <div>
        Collaboration is at the heart of his practice. His work has come to life
        across NYC and abroad, bringing together people from disparate
        backgrounds into conversation with each other.
      </div>
      <div>
        Doug has explored the state of public art in Seoul; partnered kids with
        artists and urban planners to design and build Kitty City, a city for
        kittens. He and Heidi Neilson inflated the Menu For Mars Kitchen to
        prototype food for the Red Planet. He’s collaborated with refugees from
        the Middle East and Africa who have relocated to Germany, Norway, and
        Israel.
      </div>
      <div>
        He’s currently Director of Education at Socrates Sculpture Park,
        designing moments for neighbors to have encounters with art making, the
        natural world, mindfulness; and organizing wild performative experiments
        with young people.
      </div>
    </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'Listen / Glean / Gather / Shape',
      location: Locations.Studio7,
      start: new Date("2021-12-02T17:00-07:00"),
      end: new Date("2021-12-02T18:30-07:00"),
      blurb:
        'In the talk Listen / Glean / Gather / Shape, Douglas Paulson talks about his experience working across a broad range of communities in his socially engaged creative and pedagogical practice. Through his work, Paulson considers the expanded role of the artist in socially engaged spaces and contexts designed to make space for others, while exploring the intersection of culture, environmental, and social justice issues. Paulson’s pedagogical practice reaches from university level, to museums and non-profits in NYC. He works with community members of all ages to build bodies of knowledge that cultivate connectedness to both our pasts and look towards the future. Paulson will talk about his ongoing work exploring cultural and environmental understanding and mindfulness with artists, farmers, and neighbors, in a world that is rapidly changing.',
      blurbJSX: (
        <div className="flex flex-col space-y-4">
          <div>
            In the talk Listen / Glean / Gather / Shape, Douglas Paulson talks
            about his experience working across a broad range of communities in
            his socially engaged creative and pedagogical practice. Through his
            work, Paulson considers the expanded role of the artist in socially
            engaged spaces and contexts designed to make space for others, while
            exploring the intersection of culture, environmental, and social
            justice issues.
          </div>
          <div>
            Paulson’s pedagogical practice reaches from university level, to
            museums and non-profits in NYC. He works with community members of
            all ages to build bodies of knowledge that cultivate connectedness
            to both our pasts and look towards the future.
          </div>
          <div>
            Paulson will talk about his ongoing work exploring cultural and
            environmental understanding and mindfulness with artists, farmers,
            and neighbors, in a world that is rapidly changing.
          </div>
        </div>
      ),
      media: [
        {
          type: 'image',
          src: Park1,
          alt: '"Let\'s Talk" at Socrates Sculpture Park (photo 1)',
          description:
            'Photo of Doug wearing straw hat and covid mask arranging letters on a sign that says "Have you talked to your family about racism"',
        },

        {
          type: 'image',
          src: Park2,
          alt: '"Let\'s Talk" at Socrates Sculpture Park (photo 2)',
          description:
            'Close up photo of arranging letters on a sign that says "Have you talked to your family about racism"',
        },

        {
          type: 'image',
          src: Park3,
          alt: '"Let\'s Talk" at Socrates Sculpture Park (photo 3)',
          description:
            'Photo of 12 kids holding hands and walkgin in a park wearing red headbands and being led by Doug.',
        },

        {
          type: 'image',
          src: Park4,
          alt: '"Let\'s Talk" at Socrates Sculpture Park (photo 4)',
          description:
            'Photo of many kids playing with large transparent inflatable sculpture.',
        },
      ],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/710937299',
      },
    },
  ],
}

export default Doug
