import { Visitor, Locations } from './types'
import Profile from '../assets/media/alberto_novello/profile.jpg'
import WS01 from '../assets/media/alberto_novello/ws01.jpg'
import WS02 from '../assets/media/alberto_novello/ws02.jpg'
import WS03 from '../assets/media/alberto_novello/ws03.jpg'

const Alby: Visitor = {
  name: 'jesterN',
  logos: ['cmci', 'cdem', 'atlas', 'b2', 'leaf'],
  slug: 'jesterN',
  urls: ['https://www.jestern.com/', 'https://www.youtube.com/c/TheJesterN'],
  profile: {
    type: 'image',
    src: Profile,
    position: '20% 30%',
    alt: 'jesterN',
    description:
      'Photo portrait of jesterN, a white man with short hair looking up and to the right with orange light on his face against a blue backdrop.'
  },
  bio: 'JesterN’s practice repurposes found or decontextualised analogue devices to investigate the connections between light and sound in the form of contemplative installations and performances. He repairs and modifes tools from our analogue past: oscilloscopes, early game consoles, analogue video mixers, and lasers.He is attracted to their intrinsic limitations and strong ‘personalities’: fuid beam movement, vivid colors, infnite resolution, absence of frame rate, and line aesthetics.By using these forgotten devices, he exposes the public to the aesthetic diferences between the ubiquitous digital projections and the vibrance of analogue beams, engaging them to refect on the sociopolitical impact of technology in a retrospective on technologisation: what "old" means, and what value the "new" really adds. His productions in form of performances, talks, papers and compositions have been presented at Centre Pompidou in Paris, Museo Reina Sofa Madrid, Ars Electronica Linz, Amsterdam Dance Event, Venice Biennale, National Art Museo of Lima, New York Computer Music Festival, Bozar Bruxelles, BOA Biennale Porto, Rewire Festival Den Haag, Glasgow Contemporary Art Center, National Art Museum Buenos Aires, Dom Moskow, Seoul International Music Festival, Imagen Festival Colombia, Rome University of Fine Arts, to mention a few. He has released records for Staalplat, Bowindo, Elli Records, Dobialabel, Setoladimaiale, Ante- Rasa and Creative Sources. He graduated in Nuclear Physics at the University of Trieste, completed the master Art Science Technologies with Jean Claude Risset, obtained a PhD degree at the Technische Universiteit Eindhoven with Armin Kohlrausch, and graduated in Electronic Music at the Institute of Sonology, Royal Conservatory of Den Haag.He worked for Texas Instruments, Philips Research, and Auro Technologies creating sofware for their audio applications.',
  bioJSX: (
    <div>
      <div>JesterN’s practice repurposes found or decontextualised analogue devices to investigate the
        connections between light and sound in the form of contemplative installations and performances. He repairs and
        modifies tools from our analogue past: oscilloscopes, early game consoles, analogue video mixers, and lasers. He is
        attracted to their intrinsic limitations and strong ‘personalities’: fluid beam movement, vivid colors, infinite resolution,
        absence of frame rate, and line aesthetics. By using these forgotten devices, he exposes the public to the aesthetic
        differences between the ubiquitous digital projections and the vibrancy of analogue beams, engaging them to reflect
        on the sociopolitical impact of technology in a retrospective on technologisation: what ‘old’ means, and what value the
        ‘new’ really adds.</div>
      <div>His productions in form of performances, talks, papers and compositions have been presented at Centre Pompidou in
        Paris, Museo Reina Sofa Madrid, Ars Electronica Linz, Amsterdam Dance Event, Venice Biennale, National Art Museo
        of Lima, New York Computer Music Festival, Bozar Bruxelles, BOA Biennale Porto, Rewire Festival Den Haag,
        Glasgow Contemporary Art Center, National Art Museum Buenos Aires, Dom Moskow, Seoul International Music
        Festival, Imagen Festival Colombia, Rome University of Fine Arts, to mention a few.
        He has released records for Staalplat, Bowindo, Elli Records, Dobialabel, Setoladimaiale, Ante-Rasa and Creative
        Sources. </div>
      <div>He graduated in Nuclear Physics at the University of Trieste, completed the master Art Science Technologies with
        Jean Claude Risset, obtained a PhD degree at the Technische Universiteit Eindhoven with Armin Kohlrausch, and
        graduated in Electronic Music at the Institute of Sonology, Royal Conservatory of Den Haag. He worked for Texas
        Instruments, Philips Research, and Auro Technologies creating software for their audio applications.</div>
    </div>
  ),
  events: [
    {
      kind: 'workshop',
      title: 'Visual Music',
      subtitle: 'controlling Laser and Vector Monitors with sound',
      location: Locations.Studio7,
      start: new Date("2024-04-22T13:00-06:00"),
      end: new Date("2024-04-22T14:15-06:00"),
      blurb: 'In this multidisciplinary workshop that introduces and merges different theoretical fields of media archaeology, analog electronics, graphic design, optics, computer vision, coding and early 3d modeling for 80s gaming, jesterN will demonstrate intuitive techniques for visualizing sound with lasers. The images we create in this workshop will be a direct translation of sound. Visualizing sound through light reveals for the eyes several sound properties and geometries that could otherwise remain unnoticed by the ears: frequency ratios, phase shifts, detuning and beatings, etc.',
      blurbJSX: (
        <div>
          <div>
            In this multidisciplinary workshop that introduces and merges different theoretical fields of media archaeology, analog electronics, graphic design, optics, computer vision, coding and early 3d modeling for 80s gaming, jesterN will demonstrate intuitive techniques for visualizing sound with lasers. The images we create in this workshop will be a direct translation of sound. Visualizing sound through light reveals for the eyes several sound properties and geometries that could otherwise remain unnoticed by the ears: frequency ratios, phase shifts, detuning and beatings, etc.,
          </div>
          Participants are encouraged to bring a laptop (and laser or oscilloscopes if you have them).  Knowledge of Max/MSP, Puredata, Processing is a plus, but not necessary.
          <div>
          </div>
        </div>
      ),
      media: [
        {
          type: 'image',
          src: WS01,
          alt: '',
          description: ''
        },
        {
          type: 'image',
          src: WS02,
          alt: '',
          description: ''
        },


        {
          type: 'image',
          src: WS03,
          alt: '',
          description:
            '',
        },
      ],
    },
  ],
}

export default Alby
