import { Visitor, Locations } from './types'
import Profile from '../assets/media/desertArtLAB/The_Desertification_Cookbook_brings_new_life_to_a_dry_wasteland.jpg'
import DesertFormal from '../assets/media/desertArtLAB/02desertformal3.jpg'
import Exhibit01 from '../assets/media/desertArtLAB/05_2017_05_25_desert_lab_exhibition_010.jpg'
import Exhibit02 from '../assets/media/desertArtLAB/07_2017_05_25_desert_lab_exhibition_009.jpg'
import Exhibit03 from '../assets/media/desertArtLAB/2017_05_25_desert_lab_exhibition_014.jpg'
import Garden from '../assets/media/desertArtLAB/DSC00984.jpg'

const DesertArtLAB: Visitor = {
  name: 'DesertArtLAB',
  slug: 'desertartlab',
  logos: ['cmci', 'cdem'],
  urls: [
    'http://desertartlab.com',
    'https://instagram.com/desertartlab'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'DesertArtLAB',
    description: 'Photo portrait of Matt and April standing next to a three wheel light green cargo bike, both with dark blue overalls.'
  },
  bio: 'DesertArtLAB is an interdisciplinary environmental arts collaborative co-directed by April Bojorquez and Matt Garcia. Their work promotes Indigenous/Chicanx perspectives on ecological practice, food sovereignty, self-determination, and climate change. DesertArtLAB’s projects activate public space through participatory artworks and support the restoration of desert environments and their foodways through zero irrigation regrowth projects. DesertArtLAB have presented their work nationally and internationally at Ecole Nationale Supérieure des Beaux-Arts, Paris, France; The Dom Museum, Vienna, Austria; The Museum of Contemporary Native Art, Santa Fe, NM; the Museum of Contemporary Art Santa Barbara, Galería de la Raza, San Francisco, CA, among many others. April and Matt are recipients of the Creative Capital award and were 2021 Mellon Artists in Residence at the Colorado College Fine Arts Center Museum. Bojorquez and Garcia live in Pueblo, Colorado where they work as artists/educators. Bojorquez is an educator at the Chavez Huerta Preparatory Academy and Garcia is an Assistant Professor of Creativity + Practice at Colorado State University Pueblo.',
  events: [
    {
      kind: 'talk',
      title: 'The Desertification Cookbook',
      subtitle: 'Revitalizing Desert Wasteland through Land Art',
      location: Locations.Hale270,
      start:  new Date("2023-02-01T17:00-07:00"),
      end:  new Date("2023-02-01T18:30-07:00"),
      blurb: 'Development in desert cities has created new homes and opportunities in the Southwest, but has also stripped away parts of the natural environment and its rich history. Can urban pockets of degraded land be revitalized? Can the history and the ecological value of these places be reclaimed sustainably? The answers might be in a 30-year land art project in Pueblo, Colorado. Matt Garcia and April Bojorquez, artists, educators, and founders of desert ArtLab, transform “wasteland” into a productive and edible landscape in their public art initiative: “The Desertification Cookbook.”  They bring together art and place, ecology and community, in an ambitious multi-phase project set to span decades. Join us for a conversation with Garcia and Bojorquez as they discuss the development of their land art project, from its roots in Phoenix to its realization in Pueblo.',
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/797990519'
      },
      media: [
        {
          type: 'image',
          src: DesertFormal,
          alt: 'Desert Formal',
          description: 'Photo of white plate on white table cloth with two forks on the left and a knife and two spoons on the right.  A small green cactus with pricks sits on the plate'
        },
        {
          type: 'image',
          src: Exhibit01,
          alt: 'DesertArtLAB exhibit (view 1)',
          description: 'Photo of gallery with large black writing on white wall and a short table like pedestal on the floor exhibiting many small artifacts. Behind the floor pedestal on the wall hang four printed works.'
        },
        {
          type: 'image',
          src: Exhibit03,
          alt: 'DesertArtLAB exhibit (view 2)',
          description: 'Photo of gallery with large black writing on white wall and a short table like pedestal on the floor exhibiting many small artifacts. Behind the floor pedestal on the wall hang four printed works.'
        },
        {
          type: 'image',
          src: Exhibit02,
          alt: 'DesertArtLAB exhibit (view 3)',
          description: 'Photo of gallery with four images of desert plants hanging. To the right of the images is a shelf with many desert vegetation in glass jars.'
        },
        {
          type: 'image',
          src: Garden,
          alt: 'DesertArtLAB (cactus garden)',
          description: 'Photo of cacti garden with stone covering and blue fence. In the garden are a girl in a red dress in the foreground and woman in a blue jumper in the background.'
        }

      ]
    }
  ]
}

export default DesertArtLAB
