import { Visitor, Locations } from './types'
import Profile from '../assets/media/khalik_allah/KHALIK_ALLAH_HS.jpg'
import Alk28 from '../assets/media/khalik_allah/ALK2013028G00028.jpg'
import Alk21 from '../assets/media/khalik_allah/ALK2018021G00021.jpg'
import Alk20 from '../assets/media/khalik_allah/ALK2019020G00020.jpg'

const Khalik: Visitor = {
  name: 'Khalik Allah',
  slug: 'khalik_allah',
  urls: ['http://khalikallah.com'],
  logos: ['roser', 'cmci', 'cdem'],
  profile: {
    type: 'image',
    src: Profile,
    position: '0% 40%',
    alt: 'Khalik Allah',
    description:
      'Photo portrait of Khalik in black and white with short dark hair and a goatee.',
  },
  bio: 'Khalik Allah (b. 1985) is a New York-based photographer and filmmaker who practices Camera Ministry with an eye as open as his heart. The resulting work has been described as “street opera” and noted for its beautifully visceral humanity. After a number of short films that reflect relationships formed through portraiture, Allah advanced his artistry with the feature length documentary Field Niggas (2015), shot at nighttime on the corner of Harlem’s 125th St. and Lexington Avenue. This corner also served as the basis for his first photography book Souls Against the Concrete, published by University of Texas Press in 2017. Allah continued with Black Mother (2018), an ecstatic expression of reverence and realities across Jamaica. This award winning film has been seen in festivals, museums and schools around the world; further released in the UK and the US through distributors Dogwoof and Grasshopper Film. Allah’s films are available on the Criterion Channel. Khalik is currently at work on his second photo book from 125th and Lexington. Khalik became a Magnum Photos Nominee member in 2020.',
  events: [
    {
      kind: 'talk',
      title: 'Camera Ministry',
      location: Locations.Eaton150,
      start:  new Date("2022-02-16T17:00-07:00"),
      end:  new Date("2022-02-16T18:30-07:00"),
      blurb: '',
      blurbJSX: (
        <div>
          In this workshop I will discuss the spiritual implications of my work:
          <ul className="list list-disc list-outside px-4" role="list">
            <li>
              Photographing the essence of a person from a non-judgemental
              perspective.
            </li>
            <li>Recognizing everyone as a light, and as a child of God.</li>
            <li>Choosing to see beauty everywhere and in everything.</li>
            <li>The use of photography to form intimate relationships.</li>
            <li>
              I will also discuss my own evolution as a filmmaker and
              photographer in order to lend inspiration to aspiring artists.
            </li>
            <li>I will discuss the ups and downs I&apos;ve faced.</li>
            <li>Making work that&apos;s not always understood.</li>
            <li>Finding representation, Gallery etc - Magnum Photos</li>
            <li>Balancing art and business</li>
            <li>Honesty</li>
          </ul>
        </div>
      ),
      media: [
        {
          type: 'image',
          src: Alk28,
          alt: 'Souls Against the Concrete, photo book (sample 1)',
          description:
            'Photo of black woman with short hair in a dark surround, smoking, looking up towards light.',
        },
        {
          type: 'image',
          src: Alk21,
          alt: 'Souls Against the Concrete, photo book (sample 2)',
          description:
            'Close up photo of black man in dark surroundings, looking straight at the camera, where only eyes and a few facial features are visible.',
        },
        {
          type: 'image',
          src: Alk20,
          alt: 'Souls Against the Concrete, photo book (sample 3)',
          description:
            'Close up photo of black man in dark surroundings, looking to the right out of view.',
        },
      ],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/701687098',
      },
    },
  ],
}

export default Khalik
