import { Visitor, Locations } from './types'

import Profile from '../assets/media/rick_prelinger/profile.jpg'
import Radio from '../assets/media/rick_prelinger/radio.jpg'
import RadioCraft from '../assets/media/rick_prelinger/radio_craft.jpg'
import BlackHam from '../assets/media/rick_prelinger/black_ham.jpg'

const Rick: Visitor = {
  name: 'Rick Prelinger',
  logos: ['cmci', 'cdem', 'english', 'iawp', 'mediastudies', 'mal', 'libraries', 'cha'],
  slug: 'rick-prelinger',
  urls: [''],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 100%',
    alt: 'Rick Prelinger',
    description:
      'A white man with white hair and black t-shirt, wearing sunglasses and holding a camera over his left eye pointed at the viewer.',
  },
  bio: 'Rick Prelinger is an archivist, filmmaker, writer and educator, currently writing a book on the history, culture and politics of “useful radio." He began collecting "ephemeral films" (films made for specific purposes at specific times, such as advertising, educational and industrial films; more recently called "useful cinema") in 1983. His collection of 60,000 films was acquired by Library of Congress in 2002, and since that time Prelinger Archives has again grown to include some 30,000 home movies and 7,000 other film items. Beginning in 2000, he partnered with Internet Archive to make a subset of the Prelinger Collection (now over 8,700 films) available online for free viewing, downloading and reuse. His archival feature Panorama Ephemera (2004) played in venues around the world, and his feature project No More Road Trips? received a Creative Capital grant in 2012. His 30 Lost Landscapes participatory urban history projects have played to many thousands of viewers in San Francisco, Detroit, Oakland, Los Angeles, New York and elsewhere. He is a board member of Internet Archive and frequently writes and speaks on the future of archives. With Megan Prelinger, he co-founded Prelinger Library, an experimental research library in downtown San Francisco, in 2004, still open to the public. He is currently Emerit Professor of Film & Digital Media at University of California, Santa Cruz.',
  events: [
    {
      kind: 'talk',
      title: 'Exploring the Other Spectrum',
      subtitle: 'Useful Radio',
      location: Locations.BruceCurtis,
      start: new Date("2023-10-18T17:00-06:00"),
      end: new Date("2023-10-18T18:30-06:00"),
      blurb: 'Useful radio is radio with a job to do, as distinct from broadcast radio whose function is to entertain, inform and exhort. Useful radio regulates the labor and mobility of humans, animals and vehicles on land, sea and in the air; supports state functions, including policing and surveillance; enables infrastructures of production, distribution and consumption. As a multidirectional system of transmitting and diffusing commands, instructions and situational information, useful radio has from its origins simultaneously mapped and reproduced geographies of power and control. At the same time, manifestations like citizens band (CB) and low-power handheld radios have enabled uncountable acts of resistance and rebellion. Useful radio has played a key role in workers’ organizations, civil rights and antiwar movements and youth rebellion, and it might be considered the pulse of the January 6 Capitol riot. This talk presents sounds and images together with elements of a collective research agenda in the hope of encouraging fieldwork, research, fabulation and critique within this dominant but typically unheard and barely theorized sector of the wireless spectrum.',
      media: [
        {
          type: 'image',
          src: RadioCraft,
          description: 'Magazine cover of "Radio Craft" with picture of dog with radio strapped to its body and quote "Her master\'s voice - by Radio"',
          alt: ''
        },
        {
          type: 'image',
          src: BlackHam,
          description: 'Three old black and white pictures, each with a black male radio operator with white collared shirts and a tie',
          alt: '',
        },
        {
          type: 'image',
          src: Radio,
          description: 'Black and white photo of three black men and three black women sitting around a CB radio with the man in foreground speaking into the microphone',
          alt: ''
        },
      ],
    },
    {
      kind: 'talk',
      title: 'Quiet Archives and Noisy Audiences',
      subtitle: 'Building a Future for Film',
      location: Locations.Atlas102,
      start: new Date("2023-10-19T12:30-06:00"),
      end: new Date("2023-10-19T13:45-06:00"),
      blurb: '',
      media: [],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/913089433'
      }
    }
  ],
}

export default Rick
