import { Visitor, Locations } from './types'

import Profile from '../assets/media/colin_ives/profile.jpg'
import Catalogue from '../assets/media/colin_ives/catalogue_colin_ives_vibrant_landscapes.jpg'
import Plant1 from '../assets/media/colin_ives/plant11_RC_2.jpg'
import Plant2 from '../assets/media/colin_ives/plant14.jpg'
import Waterfall from '../assets/media/colin_ives/waterfall_colin_ives_vibrant_landscapes.jpg'

const Colin: Visitor = {
  name: 'Colin Ives',
  logos: ['cmci', 'cdem'],
  slug: 'colin-ives',
  urls: ['https://colinives.com/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Colin Ives',
    description:
      'Black and white photo of white man facing camera with plaid shirt'
  },
  bio: "Colin Ives is a media artist whose creative practice operates within a nexus of overlapping cultural categories, including art, technology, and ecology.He uses technology not as an end in itself, never an unexamined tool, but as a chance to reflect, examine, and reveal aesthetic and cultural substructures.Across a diverse range of work, including media installation, kinetic video sculpture, sculptural objects, and interactive screen- based work, he explores how our digital tools are not only changing our capabilities but also our worldview. His current work with AI does not rely on readily available AI models.He holds that his own tangible experience in the landscape or in selecting and arranging flowers is essential to his work, anchoring his artistic expression.He painstakingly captures his own video footage of living forms and ecosystems, reflecting the intricate ecological complexities in every model he trains.  His AI Creative Practice Research Group(AICP) collaborated on a project with the Grammy - nominated vocal quartet New York Polyphony called Aleph Earth, which has been screened internationally.His current projects, 'Garden in the Machine,' were showcased at the New Currents festival in New Mexico, and 'Vibrant Landscapes' was featured at the International Symposium of Electronic Art 2022 in Barcelona.His work Forest Passage was featured in Transpecies Design at The European Cultural Centre for the 2023 Architecture Biennial in Venice, Italy. ",
  bioJSX: (<div className="space-y-4">
    <div>
      Colin Ives is a media artist whose creative practice operates within a nexus of overlapping cultural categories, including art, technology, and ecology. He uses technology not as an end in itself, never an unexamined tool, but as a chance to reflect, examine, and reveal aesthetic and cultural substructures. Across a diverse range of work, including media installation, kinetic video sculpture, sculptural objects, and interactive screen-based work, he explores how our digital tools are not only changing our capabilities but also our worldview.
    </div><div>
      His current work with AI does not rely on readily available AI models. He holds that his own tangible experience in the landscape or in selecting and arranging flowers is essential to his work, anchoring his artistic expression. He painstakingly captures his own video footage of living forms and ecosystems, reflecting the intricate ecological complexities in every model he trains.
    </div><div>
      His AI Creative Practice Research Group (AICP) collaborated on a project with the Grammy-nominated vocal quartet New York Polyphony called Aleph Earth, which has been screened internationally. His current projects, &lsquo;Garden in the Machine,&rsquo; were showcased at the New Currents festival in New Mexico, and &lsquo;Vibrant Landscapes&rsquo; was featured at the International Symposium of Electronic Art 2022 in Barcelona. His work Forest Passage was featured in Transpecies Design at The European Cultural Centre for the 2023 Architecture Biennial in Venice, Italy.
    </div><div>
    </div>
  </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'Now Casting',
      location: Locations.Atlas100,
      start: new Date("2024-02-27T14:00-07:00"),
      end: new Date("2024-02-27T15:15-07:00"),
      blurb: 'The term "now casting" comes from meteorology. It\'s the report of current weather conditions based on computer modeling rather than direct observation or real- time reading of sensors in the field.It particularly revealing of our cultural moment: we live at a time when for any given action, predictive calculations are constantly occurring.A ubiquitous example is data mining and targeted advertising, but predictive analytics powered by Artificial Intelligence is deployed on all levels of our social structure, from policing and medicine to models of climate change.Within my practice and my use of Ai the generative aspect of this “casting” serves as an analogy for the vitality at play in the world around us.The spatiotemporal patterns in the work reflect ecological complexities and speak to the limits of a human- only frame of reference.I grew up at the base of the Flat Irons in Boulder, Colorado, the child of geographers.On family hikes, my parents gave a telling of the landscape that made visible the radical upheavals that had created the mountainous spaces we were traversing.The landscape wasn\'t static for me, but a place full of striations, erratics, and hanging U- shaped valleys: evidence of spatiotemporal flows operating beyond the scope of daily human assumptions. ',
      blurbJSX: (
        <div>
          <div>
            The term &ldquo;now casting&rdquo; comes from meteorology. It&apos;s the report of current weather conditions based on computer modeling rather than direct observation or real-time reading of sensors in the field. It particularly revealing of our cultural moment: we live at a time when for any given action, predictive calculations are constantly occurring. A ubiquitous example is data mining and targeted advertising, but predictive analytics powered by Artificial Intelligence is deployed on all levels of our social structure, from policing and medicine to models of climate change. Within my practice and my use of Ai the generative aspect of this “casting” serves as an analogy for the vitality at play in the world around us. The spatiotemporal patterns in the work reflect ecological complexities and speak to the limits of a human-only frame of reference.

          </div><div>
            I grew up at the base of the Flat Irons in Boulder, Colorado, the child of geographers. On family hikes, my parents gave a telling of the landscape that made visible the radical upheavals that had created the mountainous spaces we were traversing. The landscape wasn&apos;t static for me, but a place full of striations, erratics, and hanging U-shaped valleys: evidence of spatiotemporal flows operating beyond the scope of daily human assumptions.
          </div>
        </div>

      ),
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/917668741'
      },
      media: [
        {
          type: 'image',
          src: Catalogue,
          alt: '',
          description:
            'Watery AI generated image the mixes seaguls with an image of the coast.',
        },
        {
          type: 'image',
          src: Plant1,
          alt: '',
          description:
            'Watery AI generated graphic if a plant, perhaps a lilly.',
        },
        {
          type: 'image',
          src: Plant2,
          alt: '',
          description:
            'Watery AI graphic if a blue flower.',
        },
        {
          type: 'image',
          src: Waterfall,
          alt: '',
          description:
            'Image of a waterfall that has some sort of swirl AI effect',
        },
      ],
    },
  ],
}

export default Colin
