import { Visitor, Locations } from './types'
import Profile from '../assets/media/jussi_parikka/profile.jpg'
import SeedBombs from '../assets/media/jussi_parikka/seedbombs.png'

const Parikka: Visitor = {
  name: 'Jussi Parikka',
  slug: 'jussi_parikka',
  logos: ['mal', 'cmci', 'iawp' ],
  urls: ['http://jussiparikka.net'],
  profile: {
    type: 'image',
    src: Profile,
    position: 'center 20%',
    alt: 'Jussi Parikka',
    description:
      'Black and white photo of Jussi Parikka in black shirt, dark thick glasses with a shaved head and a smile.',
  },
  bio: 'Dr Jussi Parikka is Professor of Technological Culture & Aesthetics at Winchester School of Art (University of Southampton) and starting in January 2022, Professor of Digital Aesthetics at Aarhus University (Denmark). He is member of Academia Europeae and Visiting Professor at FAMU at the Academy of Performing Arts in Prague where he leads the project Operational Images and Visual Culture (2019-2023). His published books include Insect Media (2010), Digital Contagions (2007/2016) and A Geology of Media (2015), and A Slow, Contemporary Violence (2016). Recently, he co-edited (with Tomas Dvorak) Photography Off the Scale (2021) and he is the co-author (with Lori Emerson and Darren Wershler) of The Lab Book: Situated Practices in Media Studies (2022).',
  events: [
    {
      kind: 'talk',
      title: 'Operational Images and Critical Posthumanities',
      location: Locations.Online,
      start:  new Date("2022-04-27T11:00-06:00"),
      end:  new Date("2022-04-27T12:30-06:00"),
      blurb:
        'This online talk draws on the Operational Images project at FAMU / Academy of Performing Arts (Prague) and discusses some of the links between the notion coined by Harun Farocki with media archaeology and critical posthumanities (such as articulated e.g. by Rosi Braidotti). Operational images are thus not read only as a description of a particular class of images (e.g. pertaining to machine vision and AI) but as a conceptual device that has facilitated discussions across different disciplines and thus also shown usefulness in discussing issues that relate to the broader field of political aesthetics of images in relation to for example extractivism.',
      media: [
        {
          type: 'image',
          src: SeedBombs,
          alt: 'Still from video, "Seed, Image, Ground" (2020), courtesy of Abelardo Gil-Fournier and Jussi Parikka',
          description:
            'Split screen video still with image of a landscape with overlaid contour lines on left, and medium view of drone helicopter in a field on right.',
        },
      ],
    },
  ],
}

export default Parikka
