import { Visitor, Locations } from './types'
import Profile from '../assets/media/michael_marder/profile.jpg'

const Marder: Visitor = {
  name: 'Michael Marder',
  slug: 'michael-marder',
  logos: ['roser', 'missionzero', 'cmci', 'cdem' ],
  urls: [
    'https://www.michaelmarder.org'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Michael Marder',
    description: 'Photo portrait of Michael Marder from behind looking off to the right.'
  },
  bio: 'Michael Marder is IKERBASQUE Research Professor in the Department of Philosophy at the University of the Basque Country (UPV-EHU), Vitoria-Gasteiz, Spain. His writings span the fields of ecological theory, phenomenology, and political thought. He is the author of numerous scientific articles and monographs, including Plant-Thinking (2013); Phenomena—Critique—Logos (2014); The Philosopher’s Plant (2014); Dust (2016), Energy Dreams (2017), Heidegger (2018), Political Categories (2019), Pyropolitics (2015, 2020); Dump Philosophy (2020); Hegel\'s Energy (2021); Green Mass (2021) and Philosophy for Passengers (2022), among others. For more information, consult his website michaelmarder.org.',
  events: [
    {
      kind: 'talk',
      title: 'The Phoenix Complex',
      subtitle: 'A Primer',
      location: Locations.Hale270,
      start:  new Date("2023-02-13T17:00-07:00"),
      end:  new Date("2023-02-13T18:30-07:00"),
      blurb: 'The phoenix complex: we are all suffering from it, individually and collectively. It is in us, having become entrenched in minds and bodies over millennia, while we are trapped in it, our practices and infrastructures servicing its many units and component parts. Even more so, the fate of the livable world well beyond the human sphere is hanging in the balance on account of this cross-cultural and transhistorical, in equal measure psychological and political, religious and philosophical, complex. If it still goes undiagnosed, that is because the mélange of practices, narratives, discourses, beliefs, and hopes comprising the phoenix complex has not yet been formally called by its proper name. And, assuming that scholars duly recognize and classify it, this predicament will likely not be acknowledged as a problem at all, but as a blessing in the form of the infinitely self-regenerative capacity inherent to finite existence.\n\nIn this talk we will consider the main features of the phoenix complex, which organizes our relation to the natural world and to ourselves. As we will come to realize, in order to be effective, ecological theory and practice must work through this complex, contending with it on a par with the environmental devastation it has wrought.',
      blurbJSX: (<div className='flex flex-col gap-2'><div>The phoenix complex: we are all suffering from it, individually and collectively. It is in us, having become entrenched in minds and bodies over millennia, while we are trapped in it, our practices and infrastructures servicing its many units and component parts. Even more so, the fate of the livable world well beyond the human sphere is hanging in the balance on account of this cross-cultural and transhistorical, in equal measure psychological and political, religious and philosophical, complex. If it still goes undiagnosed, that is because the mélange of practices, narratives, discourses, beliefs, and hopes comprising the phoenix complex has not yet been formally called by its proper name. And, assuming that scholars duly recognize and classify it, this predicament will likely not be acknowledged as a problem at all, but as a blessing in the form of the infinitely self-regenerative capacity inherent to finite existence./</div><div>In this talk we will consider the main features of the phoenix complex, which organizes our relation to the natural world and to ourselves. As we will come to realize, in order to be effective, ecological theory and practice must work through this complex, contending with it on a par with the environmental devastation it has wrought.</div></div>),
      media: [],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/814026304'
      },
    },
    {
      kind: 'roundtable',
      title: 'To (Re)Invent Nature!',
      subtitle: 'Roundtable with Michael Marder',
      location: Locations.Studio7,
      start:  new Date("2023-02-13T11:00-07:00"),
      end:  new Date("2023-02-13T13:00-07:00"),
      blurb: 'Following an examination of the contemporary critiques of "nature," Michael Marder proposes an alternative approach, mindful of environmental finitude, on the one hand, and of the concept&apos;s hidden promise, on the other. Together, we will consider the possibility of a philosophy of "nature" in and for the twenty-first century.',
      blurbJSX: (<div className='space-y-4'>
        <div>
          Following an examination of the contemporary critiques of &ldquo;nature&rdquo;, Michael Marder proposes an alternative approach, mindful of environmental finitude, on the one hand, and of the concept&apos;s hidden promise, on the other. Together, we will consider the possibility of a philosophy of &ldquo;nature&rdquo; in and for the twenty-first century.
        </div>
      </div>),
      media: []
    }

  ]
}

export default Marder
