import { Visitor, Locations } from './types'
import Profile from '../assets/media/maryam_muliaee/profile.png'
import Exaptation01 from '../assets/media/maryam_muliaee/IMG_01_still from Exaptation.jpg'
import Exaptation02 from '../assets/media/maryam_muliaee/IMG_02_still from Exaptation.jpg'
import Recycled from '../assets/media/maryam_muliaee/IMG_03_still from Recyceld Series.jpg'
import PriceOfOil01 from '../assets/media/maryam_muliaee/IMG_04_still from The Price of Oil.jpg'
import PriceOfOil02 from '../assets/media/maryam_muliaee/IMG_05_still from The Price of Oil.jpg'

const Maryam: Visitor = {
  name: 'Maryam Muliaee',
  slug: 'maryam-muliaee',
  logos: ['iawp', 'cmci', 'cdem'],
  urls: [
    'https://maryammuliaee.com/',
  ],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 20%',
    alt: 'Maryam Muliaee',
    description: 'Photo portrait of Maryam outside with brown hair, large round glasses with a earth green blouse.'
  },
  bio: 'Maryam Muliaee is a media artist-scholar and currently a post-doctoral associate in the Department of Critical Media Practices and Intermedia Art, Writing and Performance at the University of Colorado Boulder. She holds a PhD in Media Study (a practice-based research program at the University at Buffalo) that combines media art creations with theoretical investigations. Maryam’s work is multidisciplinary ranging from video installation, mobile/locative media, experimental animation and documentary film. \n Maryam is the co-founding editor of MAST (The Journal of Media Art Study and Theory), a peer-reviewed journal published by the University at Buffalo. She was the recipient of the advanced PhD Dissertation Fellowship Award from the UB Gender Institute (2019-2020) for her doctoral project “Feminist Media Archaeology in Artistic Practice,” and has published her research in peer-reviewed journals and books in the contexts of media archaeology and noncommunication aesthetics in media art. Maryam is the art director of Buffalo Documentary Project which explores the oral history of Buffalo and WNY through filmmaking beyond mainstream narratives. Her recent animation, The Price of Oil (2019) has received awards in international juried film festivals including FIVA Video Art Festival in Buenos Aires, and “The Unforeseen” Experimental Film Festival in Belgrade.',
  bioJSX: (
    <div className='space-y-4'>
      <div>
        Maryam Muliaee is a media artist-scholar and currently a post-doctoral associate in the Department of Critical Media Practices and Intermedia Art, Writing and Performance at the University of Colorado Boulder. She holds a PhD in Media Study (a practice-based research program at the University at Buffalo) that combines media art creations with theoretical investigations. Maryam’s work is multidisciplinary ranging from video installation, mobile/locative media, experimental animation and documentary film.
      </div>
      <div>
        Maryam is the co-founding editor of MAST (The Journal of Media Art Study and Theory), a peer-reviewed journal published by the University at Buffalo. She was the recipient of the advanced PhD Dissertation Fellowship Award from the UB Gender Institute (2019-2020) for her doctoral project “Feminist Media Archaeology in Artistic Practice,” and has published her research in peer-reviewed journals and books in the contexts of media archaeology and noncommunication aesthetics in media art. Maryam is the art director of Buffalo Documentary Project which explores the oral history of Buffalo and WNY through filmmaking beyond mainstream narratives. Her recent animation, The Price of Oil (2019) has received awards in international juried film festivals including FIVA Video Art Festival in Buenos Aires, and “The Unforeseen” Experimental Film Festival in Belgrade.
      </div>
    </div>
  ),
  events: [
    {
      kind: 'talk',
      title: 'Moving (Recycled) Images',
      subtitle: 'Feminist Media Archaeology in Artistic Practice',
      location: Locations.Hale270,
      start:  new Date("2021-10-20T17:00-06:00"),
      end:  new Date("2021-10-20T18:30-06:00"),
      blurb: 'In a lecture titled Moving (Recycled) Images, media artist-scholar Maryam Muliaee will talk about her recent works with moving images in the context of both Media Archaeology and Feminism. Media technologies are agents whose power and singularities, as consumer black boxes, remain concealed from our perception and attention. But art is capable of materializing the agency of media and rendering anarchival materialities for our experience. Muliaee’s art practice is motivated by the curiosity to reveal (some)thing hidden in technologies of every day.\n She deploys digital tools and everyday technologies such as copier/printer in non-standard ways to create a variantology of moving images, challenge the norms of authenticity and media specificity, and to ‘play’ with the materiality of audio-visual archives. In this presentation, she will talk about a series of work collectively titled as Recycled Series (2016-2020). Muliaee thinks about Feminist Media Archaeology as a promising framework that fuels both her practice and research in media art and study.\n In working with media objects and images, she adopts techniques of media archaeology and its creative methodologies of remediation, while staying informed by feminist interests in variation, mobility and haptic modes of viewings.',
      blurbJSX: (
        <div className='space-y-4'>
          <div>
            In a lecture titled Moving (Recycled) Images, media artist-scholar Maryam Muliaee will talk about her recent works with moving images in the context of both Media Archaeology and Feminism. Media technologies are agents whose power and singularities, as consumer black boxes, remain concealed from our perception and attention. But art is capable of materializing the agency of media and rendering anarchival materialities for our experience. Muliaee’s art practice is motivated by the curiosity to reveal (some)thing hidden in technologies of every day.
          </div><div>
            She deploys digital tools and everyday technologies such as copier/printer in non-standard ways to create a variantology of moving images, challenge the norms of authenticity and media specificity, and to ‘play’ with the materiality of audio-visual archives. In this presentation, she will talk about a series of work collectively titled as Recycled Series (2016-2020). Muliaee thinks about Feminist Media Archaeology as a promising framework that fuels both her practice and research in media art and study.
          </div><div>
            In working with media objects and images, she adopts techniques of media archaeology and its creative methodologies of remediation, while staying informed by feminist interests in variation, mobility and haptic modes of viewings.
          </div>
        </div>
      ),
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/651233698'
      },
      media: [
        {
          type: 'image',
          src: Exaptation01,
          alt: 'Still from Off the Path, Recycled Series, 2018',
          description: 'Black and white photo-colllage.'
        },
        {
          type: 'image',
          src: Exaptation02,
          alt: 'Still from Expatation, Recycled Series, 2019',
          description: 'Black and white photo-colllage.'
        },
        {
          type: 'image',
          src: Recycled,
          alt: '',
          description: 'Black and white photo-colllage.'
        },
        {
          type: 'image',
          src: PriceOfOil01,
          alt: 'Still from The Price of Oil, Recycled Series, 2019',
          description: 'Black and white photo-colllage.'
        },
        {
          type: 'image',
          src: PriceOfOil02,
          alt: 'Still from Expatation, Recycled Series, 2019',
          description: 'Black and white photo-colllage.'
        }
      ]
    },
  ]
}

export default Maryam
