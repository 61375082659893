import { Visitor, Locations } from './types'
import Profile from '../assets/media/elisabeth_schimana/profile.jpg'
import Media01 from '../assets/media/elisabeth_schimana/media01.jpg'
import Media02 from '../assets/media/elisabeth_schimana/media02.jpg'
import Media03 from '../assets/media/elisabeth_schimana/media03.jpg'
import Media04 from '../assets/media/elisabeth_schimana/media04.jpg'
import Media05 from '../assets/media/elisabeth_schimana/media05.jpg'

const Elise: Visitor = {
  name: 'Elisabeth Schimana',
  slug: 'elisabeth-schimana',
  logos: ['b2', 'mal', 'cmci', 'bmkoes', 'ima', 'cdem'],
  urls: [
    'https://elise.at',
    'https://ima.or.at'
  ],
  profile: {
    type: 'image',
    src: Profile,
    position: '20% 20%',
    alt: 'Elisabeth Schimana',
    description: 'Photo portrait of Elisabeth Schimana with short black hair and an earth-toned sweater and ear rings and looking to the right.'
  },
  bio: 'Since the 1980s the musician and composer Elisabeth Schimana has been active as one of the Austrian female pioneers of electronic music with projects marked by a radical approach and equally unconventional aesthetics.\nAfter completing vocal training, she earned degrees in composition, computer music, musicology, and ethnology. She has worked intensively with the theremin in Moscow and with the Max Brand Synthesizer in Vienna. Not only has she created countless radio works in cooperation with ORF Kunstradio but numerous sound installations and interdisciplinary and performative projects as well.\nIn her artistic work, Schimana examines questions of space, communication, or the body in its presence or absence, especially the imparting of compositional concepts (scores), which gives rise to completely new approaches that experimentally explore how we hear and demand a heightened musical presence on the part of the performer. Her probing approach also led her to found the IMA Institute of Media Archeology, which has dedicated itself to acoustic media at the analogue/digital interface and to the subject of women, art, and technology since 2005.',
  events: [
    {
      kind: 'talk',
      title: 'From Sta(i)rcase to Virus',
      location: Locations.Hale270,
      start: new Date('2022-09-28T17:00-06:00'),
      end: new Date('2022-09-28T18:00-06:00'),
      blurb: 'In this lecture, Elisabeth Schimana, will present her work and give insight into her thought process, questions, and research interests. With a fascination for unheard sounds, Schimana will present, among others, the following projects:\nA live radio performance from 2012, titled "7 Days and 7 nights Odessa", in which Schimana made field recordings on and around the Odessa steps, which became famous because of Sergei Eisenstein\'s 1925 silent film „Battleship Potemkin“, and where the soundtrack becomes a shaped sound body for the unheard, the not yet heard, and even the unprecedented.\nA multichannel live electronic performance from 2009, titled "Stardust", where imagined sounds from the depth of the universe are condensed into a dimensional sound space.\nAn ongoing series since 2011, titled "Virus #x", that puts an electronic sound body as a host in constant exchange with an orchestral instrument, and where the synthesis and formal struggle of both bodies are best understood as the viral logic of transmission - the sounds embodying the constant formation and collapse of an unfolding formal system.',
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/758498719'
      },
      media: [
        {
          type: 'image',
          src: Media02,
          alt: 'Odessa - night',
          description: 'Photo of the Odessa steps at night with lights'
        },
        {
          type: 'image',
          src: Media01,
          alt: '"Virus" performance - Kiev, Ukraine',
          description: 'Photo of Schimana from behind, looking at a laptop, with violin player in background.'
        },
        {
          type: 'image',
          src: Media04,
          alt: 'Virus performance - Graz, Austria',
          description: 'Photo of Schimana looking to left at her laptop with violin and brass horn player in the background.',
          position: '80% center'
        }

      ]
    },
    {
      kind: 'presentation',
      title: 'Hidden Alliances',
      blurb: 'Perplexed by what was purported to be a world without female artists in the electronic music/art genre, I embarked on a search in the 1990s. Geschichten and portrait 01 – Die Futuristin, projects produced by ORF–Austrian Broadcasting Company’s Ö1 Kunstradio - led, following the founding of the IMA Institute of Media Archeology in 2005, to the IMAfiction series, in which 10 female artists, pioneers of their time, told rather a different story and together wove a fascinating network of interrelationships. In this DVD series of five Austrian and five international artists most of the artists chose whom they wanted to be portrayed by. The results are homages that are aesthetically diverse, intimate, and refreshingly free from any conventional film format constraints.',
      location: Locations.Studio7,
      start: new Date('2022-09-16T17:30-06:00'),
      end: new Date('2022-09-16T19:00-06:00'),
      media: []
    },
    {
      kind: 'performance',
      title: 'live multi-channel electronic music',
      blurb: '1001 needle pricks\n1001 needle pricks stitch an acoustic pattern into the space. Hem stitching and cross stitching. Trickling – pouring – and subsiding.\n\n Virus # 2.3\nfor double bass and bass clarinet\nThe live generated electronic resonating body is the host to which the sounds of the string players attach and adapt, they penetrate into it and use it for their replication. At first the body is immune, but during the course of the piece the body stops resisting, takes in the sounds of the string instruments, and allows the viruses to multiply freely. It is a struggle and synthesis between two resonating bodies. Together they stay alive.\nBoth bodies are audible. The virus series is an expedition into acoustic perception, a sounding of the responses of our brains in the span of milliseconds, a plea for the precise acoustic moment. And what do you hear?',
      location: Locations.B2,
      start: new Date('2022-09-30T20:00-06:00'),
      end: new Date('2022-09-30T21:00-06:00'),
      media: [
        {
          type: 'image',
          src: Media03,
          alt: 'Elisabeth Schimana performing virus',
          description: 'Photo of Schimana looking towards her laptop and turning knobs on mixing desk with another laptop player to the left and the audience behind them both.',
          position: '80% center'
        },
        {
          type: 'image',
          src: Media05,
          alt: 'Elisabeth Schimana peforming at Pfarrhof',
          description: 'Photo of Schimana, face down, with hands crossed over a mixing console, looking concentrated.'
        }
      ]
    }
  ]
}

export default Elise
