import { Visitor, Locations } from './types'
import Profile from '../assets/media/minka/profile.jpg'
import CovidTraces from '../assets/media/minka/covidtraces.jpg'
import Hypo from '../assets/media/minka/hypo.jpg'

const Parikka: Visitor = {
  name: 'Minka Stoyanova',
  slug: 'minka_stoyanova',
  logos: ['cmci', 'cdem'],
  urls: [],
  profile: {
    type: 'image',
    src: Profile,
    position: 'center 20%',
    alt: 'Minka Stoyanova',
    description:
      'Photo of Minka Stoyanova with glasses, a smile, and large beaded necklace wearing a white shirt.',
  },
  bio: "Minka Stoyanova is an artist, technologist, and theorist. She completed her Masters of Fine Art (MFA) at the Glasgow School of Art, her PhD (Creative Media) at the School of Creative Media, City University of Hong Kong, and is an alumna of the Fulbright Research Scholar program in Bulgaria. Her artistic and academic research is focused on cyborg-based approaches to contemporary art and culture, and results in works that investigate the real and speculative effects of technology in society. She has presented artwork and published papers in a variety of national and international venues including Xi'an Museum of Contemporary Art(China), Clockenflap Music Festival(Hong Kong), the International Symposium of Electronic Art(Hong Kong), Transmediale(Berlin), Videoholica(Bulgaria), and FutureEverything(Manchester).As a 2020 Computing Innovation Fellow, Minka’s current, postdoctoral, research explores the possibilities of interactive media and augmented reality in urban spaces.",
  events: [
    {
      kind: 'talk',
      title: 'Art of Cyborgs',
      location: Locations.Eaton150,
      start:  new Date("2022-04-25T17:00-06:00"),
      end:  new Date("2022-04-25T18:00-06:00"),
      blurb:
        'In 1985, Donna Haraway declared that we were all ‘cyborgs.’ For Haraway, the largely science fictional concept provided an ‘ironic metaphor.’ The cyborg was an impossible configuration that allowed one to think beyond modernism’s problematic binaries -- like male/female or technology/nature. Today, Haraway’s declaration is almost banal. Technology penetrates almost every aspect of our lived experience. We are each extended, distributed, and quantified. But we are not free of rigid ontologies. Instead, those ontologies are concretized in (and exacerbated by) algorithms we barely understand. We are capable of speaking to someone around the globe, but often not to our neighbor. Through a discussion of my past and current practice, I will outline how a return to the cyborg (the technology/human hybrid) can provide a framework for artistic production and social critique that is authentic to and evolved from these realities.',
      media: [
        {
          type: 'image',
          src: CovidTraces,
          alt: 'Covid Traces',
          description: 'Glitched photo of black man in grean shirt and shorts.',
        },
        {
          type: 'image',
          src: Hypo,
          alt: 'Hypo',
          description: 'Photo of circular projected graphic.',
        },
      ],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/719400104',
      },
    },
  ],
}

export default Parikka
