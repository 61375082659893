import { Visitor, Locations } from './types'
import Profile from '../assets/media/anna_friz/profile.jpg'
import RedRadios from '../assets/media/anna_friz/red_radios.jpg'
import Hallicrafters from '../assets/media/anna_friz/hallicrafters.jpg'

const Friz: Visitor = {
  name: 'Anna Friz',
  slug: 'anna-friz',
  logos: ['mal', 'cmci', 'cdem'],
  urls: [
    'https://nicelittlestatic.com',
    'https://soundcloud.com/annafriz'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Anna Friz',
    description: 'Photo of Anna Friz in headphones and speaking into walkie talkie with a long antenna'
  },
  bio: 'Anna Friz creates media art, sound works and self-reflexive radio for broadcast, installation, film and performance. Her compositions reflect upon media ecologies, land use and infrastructure (human and extra-human, acoustic and electro-magnetic), time perception, and critical fictions. Her radio art/works have been heard on the airwaves of more than 25 countries, and commissioned by national public radio in Austria, Australia, Canada, Finland, Germany, and Mexico. Presentations of her work in the recent years include Ars Electronica Big Concert Night (Linz, Austria), the Museum of Arts and Design (New York), SITE Gallery (Houston), the New York Times Magazine, Tsonami Festival de Arte Sonoro (Valparaíso and Santiago, Chile), ReWire Festival (The Hague, Netherlands), Radiophrenia (Glasgow, Scotland), Soundhouse at the Barbican (London UK), Espace Multimedia Gantner (Belfort France), and Kunstradio on the cultural channel of Austria\'s national public radio. She is a long time affiliate of Wave Farm (New York) and currently is Associate Professor in Film and Digital Media at University of California Santa Cruz.',
  events: [
    {
      kind: 'talk',
      title: 'Distance, Difference and Reverie',
      subtitle: 'Encountering Transmission Ecologies Through Critical Radio Practices',
      location: Locations.Eaton150,
      start:  new Date("2022-04-13T17:00-06:00"),
      end:  new Date("2022-04-13T18:30-06:00"),
      blurb: 'Terrestrial radio listening, as in listening to waves and stations, remains both intimate and public, secret and open. Turning on and tuning across the radio dial generates no data footprint as a listener ventures across an ever-changing geography of radio spaces. Radio artists reflect and explore the feeling of distance within the shifting territories and forces that characterize transmission, including the instability of signals and the ephemeral quality of listening which may nonetheless leave deep impressions, together with the key conditions of transmission as both resolutely public and protectively intimate. To think ecologically about transmission is to consider processes of relationship and power, within both the symbolic spaces of cultural production and the material space of dynamic electro-magnetic interactions. Anna reflects on these themes through examples of solo and collaborative transmission art works.',
      media: [],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/710149157'
      }
    },
    {
      kind: 'workshop',
      title: 'Radio is not a container',
      blurb: 'Join in for a session of micro-radio play, including listening, improvisation, and collaboration with radio waves. Bring along your portable radio receiver if you have one.',
      location: Locations.MAL,
      start:  new Date("2022-04-14T14:00-06:00"),
      end:  new Date("2022-04-14T15:00-06:00"),
      media: [
        {
          type: 'image',
          src: Hallicrafters,
          alt: 'radio dial',
          description: 'Close up photo of radio dial with short, middle, and long wave channels.'
        },
        {
          type: 'image',
          src: RedRadios,
          alt: 'Anna Friz performing',
          description: 'Photo of the Anna Friz performing with multiple radios on the floor under a red light.'
        }

      ]
    }
  ]
}

export default Friz
