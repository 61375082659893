
import { Visitor, Locations } from './types'
import Profile from '../assets/media/shannon_mattern/profile.png'
import Blanket from '../assets/media/shannon_mattern/blankets.jpg'

const Shannon: Visitor = {
  name: 'Shannon Mattern',
  slug: 'shannon_mattern',
  logos: ['cmci', 'cdem', 'mediastudies', 'mal'],
  urls: ['https://wordsinspace.net'],
  profile: {
    type: 'image',
    src: Profile,
    position: '85% 100%',
    alt: 'Shannon Mattern',
    description:
      'Photo portrait of Shannon, a white woman with dark hair and eyes, framed almost entirely on the face.  She is looking straight at the camera with a soft smile, resting her face on her right hand under her chin. She is wearing a green and white striped shirt.  In the background, we see a full bookshelf.',
  },
  bio: 'Shannon Mattern is the Penn Presidential Compact Professor of Media Studies at the University of Pennsylvania and the Director of Creative Research and Practice at the Metropolitan NY Library Council. From 2004 to 2022, she served in the Department of Anthropology and the School of Media Studies at The New School in New York. Her writing and teaching focus on media architectures and information infrastructures. She has written books about libraries, maps, and urban intelligence; and she contributes a column about urban data and mediated spaces to Places Journal. She’ll be the 2025 Kluge Chair in Modern Culture at the Library of Congress. You can find her at wordsinspace.net.',
  events: [
    {
      kind: 'talk',
      title: 'Disobedient Libraries',
      location: Locations.Eaton150,
      start: new Date("2024-10-08T11:00-06:00"),
      end: new Date("2024-10-08T12:15-06:00"),
      blurb: 'Amidst widepread attacks on and disillusionment with public institutions, many activists, communitiy organizers, and artist collectives have launched informal libraries to support social movements and intentional learning communities. In this talk, we’ll examine libraries themselves as media forms, and we’ll consider how the curation, circulation, and civic activation of the various media within their collections — from books and zines to cassette tapes and pirated digital files -- constitute vibrant, creative, transformative media practices. In the process, we’ll also question what constitutes a library — just as the Media Archaeology Lab asks critical questions about what makes a lab, a lab.',
      media: [
        {
          type: 'image',
          src: Blanket,
          alt: 'A library of blankets? Seneca Nation artist Marie Watt at the Print Center New York. Photo by Shannon Mattern',
          description:
            'A stack of colorful blankets, each with a paper tag attached.',
        },
      ]
    },
  ],
}

export default Shannon
