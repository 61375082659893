import { Visitor, Locations } from './types'
import Profile from '../assets/media/steven_feld/profile.jpg'
import SF from '../assets/media/steven_feld/SF04+by+CatherineM.jpeg'
import Voices01 from '../assets/media/steven_feld/voices01.png'
import Voices02 from '../assets/media/steven_feld/voices02.png'

const Feld: Visitor = {
  name: 'Steven Feld',
  slug: 'steven-feld',
  logos: ['cmci', 'cdem'],
  urls: ['http://www.stevenfeld.net'],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Steven Feld',
    description:
      'Portrait photo of Steve Feld in black t-shirt and white background',
    position: 'center 17%',
  },
  bio: 'Steven Feld is a sound and image artist, with a background in music composition and performance and photography and filmmaking. He is Distinguished Professor Emeritus of Anthropology and Music and the University of New Mexico, and Director of VoxLox Media Arts. His books for Duke University Press include Sound and Sentiment, and Jazz Cosmopolitanism in Accra. His CDs for VoxLox and Smithsonian include the European series The Time of Bells, Bosavi: Rainforest Music from Papua New Guinea, and Por Por: Honk Horn Music of Ghana. His films for DER include the 5 DVD Jazz Cosmopolitanism in Accra series, and most recently, in surround sound, Voices of the Rainforest.',
  events: [
    {
      kind: 'talk',
      title: 'Hearing Heat',
      blurb:
        'Is anthropocenic climate change audible? In three historical media tales, from Papua New Guinea, Japan, and Greece, I answer in the affirmative, with a montage of examples gathered from ethnographic field recordings, fiction film, installation sound and video art, popular song, and contemporary art music. The talk intertwines media history and practice, with specific examples from my audio and video work of the last 45 years.',
      location: Locations.Hale270,
      start:  new Date("2022-10-24T17:00-06:00"),
      end:  new Date("2022-10-24T18:30-06:00"),
      media: [
        {
          type: 'image',
          src: SF,
          alt: 'Steven Feld',
          description:
            'Photo of  Steven Feld in the desert standing behind dry plant half covering his face with sand dunes in the background.',
          position: 'center top',
        },
      ],
    },
    {
      kind: 'screening',
      title: 'Voices of the Rainforest in 7.1 surround sound',
      blurb:
        'Voices of the Rainforest, originally a 1991 Rykodisc CD, is a documentary sound art composition of a day in the life of the Bosavi rainforest and its inhabitants. For the CD’s 25th anniversary in 2016 the original analog tape recordings were digitized, and the 60 minute piece recomposed in 7.1 cinema surround sound, in collaboration with Skywalker Sound editor Dennis Leonard. With the support of producer Mickey Hart and the School for Advanced Research, 2017-2018 was devoted to building a film around the soundtrack, drawing both from my 1976-1999 photographs, and a return to Bosavi with filmmaker Jeremiah Ra Richards. The result is an immersive eco-rockumentary, a cinematic concert for ears.',
      location: Locations.VA1B20,
      start:  new Date("2022-10-25T18:00-06:00"),
      end:  new Date("2022-10-25T19:30-06:00"),
      media: [
        {
          type: 'image',
          src: Voices01,
          alt: 'Voices of the Rainforest, film still 1',
          description:
            'Still from the documentary with superimposed image of waterfall and colorful bird',
          position: 'center center',
        },
        {
          type: 'image',
          src: Voices02,
          alt: 'Voices of the Rainforest, film still 2',
          description:
            'Still from the documentary with superimposed image of mountain and colorful bird with long beak',
          position: 'center center',
        },
      ],
    },
  ],
}

export default Feld
