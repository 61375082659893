import { Visitor, Locations } from './types'
import Profile from '../assets/media/blk_sonic_mvmnt/lamont_andre_combo_skinny.jpg'
import Raw62 from '../assets/media/blk_sonic_mvmnt/raw_62.jpg'
import Raw07 from '../assets/media/blk_sonic_mvmnt/raw_07.jpg'

const Bsm: Visitor = {
  name: 'André Zachery and LaMont Hamilton',
  logos: ['cmci', 'cdem', 'thdn', 'atlas', 'b2'],
  slug: 'blk-sonic-mvmnt',
  urls: ['https://tisch.nyu.edu/about/directory/dance/565798878', 'https://www.lamonthamilton.com/'],
  profile: {
    type: 'image',
    src: Profile,
    position: '40% 50%',
    alt: 'LaMont Hamilton (left), André Zachery (right)',
    description:
      'Photo portrait of LaMont on the left, a black man with blue shirt and medium beard wearing a headscarf, and André on the right, a black man with short beard and a black shirt, both men stairing directly into the camera. '
  },
  bio: 'Blk Sonic MvmntLab is an exploration of the synergy between Black dancing bodies and sound, delving into the interplay of physicality and acoustic resonance. Embracing diverse genders, abilities, and artistic lineages, the lab activates space through improvisation, call and response, cyphering, and play. #futuringactions become the focal point, enabling a collective reshaping of our literal and figurative landscape. Questions arise about the excavation of value as Black dancing bodies unite, guided by the simultaneous immersion in various sounds. The lab seeks to propel movement into uncharted territories, fostering a non-verbal exploration of intentions within shared spaces. Blk Sonic Mvmnt Lab contemplates the profound and ever-evolving relationship between Black movement and sound in the 21st century.',
  events: [
    {
      kind: 'talk',
      title: 'Blk Sonic Mvmnt',
      location: Locations.Atlas100,
      start: new Date("2024-02-15T14:00-07:00"),
      end: new Date("2024-02-15T15:15-07:00"),
      blurb: 'In this talk, André and LaMont will speak breifly about their individual work, and then about their collaboration within the framework of Blk Sonic MvmntLab.',
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/915358032'
      },
      media: [
        {
          type: 'image',
          src: Raw62,
          alt: 'André Zachery',
          description:
            'Photo of black man (André) in black attire dancing in a field of yellow flowers with the sun behind in the horizon.',
        },
        {
          type: 'image',
          src: Raw07,
          alt: 'André Zachery',
          description:
            'Photo of black man (André) in white attire dancing on a dirt trail with deciduous trees in the background.',
        },
      ],
    },
  ],
}

export default Bsm
