import { Visitor, Locations } from './types'

import Profile from '../assets/media/carol_sabbadini/profile.jpg'

import Guys from '../assets/media/carol_sabbadini/guys.png'
import Planetarium from '../assets/media/carol_sabbadini/planetarium.png'
import Bean from '../assets/media/carol_sabbadini/bean.png'
import Idartes from '../assets/media/carol_sabbadini/idartes.jpg'

const Carol: Visitor = {
  name: 'Carol Sabbadini',
  logos: ['cmci', 'cdem'],
  slug: 'carol-sabbadini',
  urls: ['https://www.idartes.gov.co/es'],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 100%',
    alt: 'Carol Sabbadini',
    description:
      'A Italian-Colombian woman with shoulder-length dark hair.',
  },
  bio: 'Carol Sabbadini, is a visual artist, restorer and curator of Artistic and Cultural Heritage. She holds an MA in Art History, Visual Arts from the Universitá degli Studi of Bologna (Italy),  in Audiovisual Heritage Management and an MA in the language of Contemporary Image (photography, transdisciplinary practices and video art), from the Fondazione Fotografía of Modena (Italy). She is coordinator of the Line of Art, Science and Technology of Idartes and was advisor for the formulation and development of strategic projects of the Audiovisual, Cinema and Interactive Media department of the Ministry of Culture of Colombia and from 2016 to 2020, she was an advisor to the plan of uses and the management model of the Nueva Cinemateca de Bogotá and since its inauguration, coordinator of the Creation and Experimentation area. She has been a curator of exhibitions such as a2p (https://a2p.bitmark.com/) and her works, videos and experimental films have been exhibited in galleries, museums and festivals such as: South London Gallery, European Photography, Colombian film festival Berlin, Cremona Contemporary Art Research Center and Spatiul Platforma of the National Museum of Contemporary Art in Bucharest, among others.',
  events: [
    {
      kind: 'talk',
      title: 'The Line of Art, Science and Technology of Idartes',
      subtitle: 'a public strategy focus on the expansion of artistic practices from the convergence with science and technology in Colombia.',
      location: Locations.Studio7,
      start: new Date("2023-09-11T14:00-06:00"),
      end: new Date("2023-09-11T16:30-06:00"),
      blurb: 'The Art, Science and Technology Line is a district strategy and a national benchmark that generates collaborative spaces in the city of Bogotá for experimentation and entrepreneurship, where dialogues between art and technology allow convergence between different agents, creators, communities and ecosystems for artistic, technological and social innovation. Its programming includes activities such as laboratories, interactive workshops, exhibitions, festivals, technological developments of software and hardware, colloquiums, hackathons, and a wide range of scholarships and national and international residencies based on co-creation, research, exploration, exchange, networking and knowledge replication, which has allowed the strengthening of the experimental art and new media scene  in Colombia.',
      media: [
        {
          type: 'image',
          src: Idartes,
          description: 'graphic logos for idartes, ck:web and plataforma',
          alt: '',
        },
        {
          type: 'image',
          src: Planetarium,
          description: 'Performers in white futuristic costumes on stage in the middle of a planetarium.',
          alt: '',
        },
        {
          type: 'image',
          src: Bean,
          description: 'Multiple variety of beans on a grey table.',
          alt: ''
        },
        {
          type: 'image',
          src: Guys,
          description: 'Three people sitting around a table full of electronics and cables. Man on the left has dreadlocks and is leaning with hands on desk.  The man int he background is wearing a large breathing mask and holding a blue container.  The women in the foreground has dark hair and is seated.',
          alt: ''
        },

      ],
    },
  ],
}

export default Carol
