import { Visitor, Locations } from './types'
import Profile from '../assets/media/maria_chavez/profile.jpg'
import Media2022 from '../assets/media/maria_chavez/20220730_143246.jpg'
import MediaESS from '../assets/media/maria_chavez/MariaChavez_ESSforNorthernSpy_DevinKenny.jpg'
import MediaTextbook from '../assets/media/maria_chavez/MariaChavez_textbook_Routledge.jpg'

const Chavez: Visitor = {
  name: 'Maria Chávez',
  slug: 'maria-chavez',
  logos: ['cmci', 'cdem'],
  urls: [
    'https://mariachavez.bandcamp.com',
    'https://soundcloud.com/maria-chavez',
    'https://www.instagram.com/chavezsayz',
    'https://twitter.com/Chavezsayz',
    'https://www.facebook.com/MariaChavezSound'
  ],
  profile: {
    type: 'image',
    src: Profile,
    alt: 'Maria Chávez',
    description: 'Portrait photo of Maria with long black hair and orange background, wearing partially see-through printed shirt, looking upwards with mischeavous smile.'
  },
  bio: 'Born in Lima, Peru and based in NYC, Maria Chávez is best known as an abstract turntablist, sound artist and DJ. Coincidence, chance & failures are themes that unite her book objects, sound sculptures, installations & other works with her improvised solo turntable performance practice.\nCurrently, Maria is on the cover of the textbook on the History of Experimental & Electronic music by Routledge Publishing. Her large scale sound & multi-media installations along with other works have been shown at the Getty Museum, the JUDD Foundation in Marfa, TX, Documenta 14 in Kassel, Germany and HeK (Haus der elektronischen Künste Basel) amongst many other institutions around the world. Her latest solo exhibition, a retrospective of all of her works on paper, was on view at the Sheehan Gallery at Whitman College in Walla Walla, Washington (Feb. 1- April 15. 2022). She is currently on a medical sabbatical due to receiving brain surgery in Feb. 2019 and will return to performing for the public in 2023. She appreciates everyone\'s patience and compassion during this difficult time.',
  events: [
    {
      kind: 'talk',
      title: 'The Topography of Sound',
      subtitle: '2007- 2022',
      blurb: 'Drawing on her latest body of work, a series of marble sculptures handmade in Carrara, Italy by Maria, reveals a parallel between marble and vinyl. Maria breaks it all down (literally and figuratively) in this new lecture about the next phase of her artistic practice.',
      location: Locations.Hale270,
      start:  new Date("2022-11-02T17:00-06:00"),
      end:  new Date("2022-11-02T18:30-06:00"),
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/772629646'
      },
      media: [
        {
          type: 'image',
          src: Media2022,
          alt: 'The Perfect Beak no. 1',
          description: 'Photo of flat white stone with curved lines carved into it'
        },
        {
          type: 'image',
          src: MediaTextbook,
          alt: 'Electronic and Experimental Music Textbook with Maria on cover',
          description: 'Cover of book with photo of Maria and brown background, sitting at table with turntable and mixer and multiple records strewn about.'
        },
        {
          type: 'image',
          src: MediaESS,
          alt: 'Maria Chavez at turntables',
          description: 'Photo of Maria with bright green background, wearing a white batik outfit covering her face and hands, standing and playing on two turntables.'
        }

      ]
    }
  ]
}

export default Chavez
