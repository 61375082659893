import { Visitor, Locations } from './types'

import Profile from '../assets/media/andres_burbano/profile.jpg'
import Different from '../assets/media/andres_burbano/different.jpg'

const Andres: Visitor = {
  name: 'Andrés Burbano',
  logos: ['cmci', 'cdem'],
  slug: 'andres-burbano',
  urls: [
    'http://www.burbane.net/',
    'https://www.taylorfrancis.com/books/mono/10.4324/9781003172789/different-engines-andr%C3%A9s-burbano',
    'https://www.routledge.com/Different-Engines-Media-Technologies-From-Latin-America/Burbano/p/book/9781032001111',
  ],
  profile: {
    type: 'image',
    src: Profile,
    position: '50% 30%',
    alt: 'Andrés Burbano',
    description:
      'A dark-skin man with black hair and black glasses looking directly at the viewer and wearing a white tshirt that says "Marching for Science"'
  },
  bio: 'Andrés Burbano is Professor in the Arts and Humanities School at the Open University of Catalunya (Barcelona, Spain) and Visiting Lecturer at Donau-Universität (Krems, Austria). He holds a Ph.D. in Media Arts and Technology from the University of California at Santa Barbara (California, EEUU) and has developed most of his academic career in the School of Architecture and Design at Universidad de los Andes (Bogotá, Colombia). Burbano works as a researcher, curator, and interdisciplinary artist. His research projects focus on media history and media archaeology in Latin America and the Global South, 3D modeling of archaeological sites, and computational technologies\' historical and cultural impact. Burbano has been appointed as ACM SIGGRAPH 2024 Chair.',
  events: [
    {
      kind: 'talk',
      title: 'Different Engines',
      subtitle: 'Media Technologies from Latin America (book presentation)',
      location: Locations.BruceCurtis,
      start: new Date("2023-11-06T17:00-07:00"),
      end: new Date("2023-11-06T18:30-07:00"),
      blurb: 'Different Engines investigates the emergence of technologies in Latin America to create images, sounds, video games, and physical interactions. The book contributes to the construction of a historiographical and theoretical framework for understanding the work of creators who have been geographically and historically marginalized through the study of five exemplary and yet relatively unknown artifacts built by engineers, scientists, artists, and innovators. It offers a broad and detailed view of the complex and sometimes unlikely conditions under which technological innovation is possible and of the problematic logics under which these innovations may come to be devalued as historically irrelevant.',
      media: [
        {
          type: 'image',
          src: Different,
          description: 'Book cover design with graphic of psuedo electronic circuits.',
          alt: ''
        }
      ],
      recording: {
        kind: 'vimeo',
        url: 'https://vimeo.com/920174174'
      },

    },
  ],
}

export default Andres
